import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import WaveIllustration from '../../assets/images/illustration-wave.svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100vh',
    position: 'relative',
    background: `url(${WaveIllustration})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
  loginContainer: {
    height: 500,
    display: 'flex',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 16,
    background: theme.palette.common.white,
    boxShadow: '0px 16px 40px rgba(125, 125, 125, 0.1)',
    overflow: 'hidden',
  },
  illustrationContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.light,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  illustration: {
    height: 350,
    width: 350,
    [theme.breakpoints.down('md')]: {
      height: 250,
      width: 250,
    },
    [theme.breakpoints.down('sm')]: {
      height: 180,
      width: 180,
    },
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textTitle: {
    fontWeight: 700,
  },
}));

export default useStyles;
