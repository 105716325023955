import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Avatar,
  Box,
  Typography,
  Chip,
  ListItemIcon,
  ListItem,
  Divider,
  Paper,
  TablePagination,
  useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import useStyles from './ForumCardDetail.styles';
import ForumCard from '../../components/ForumCard';
import arlogo from '../../assets/images/arlogo.png';
import ForumGroup from '../../utils/interfaces/forumGroup.interface';
import { Comments } from '../../utils/interfaces/comments.interface';
import Group from '../../utils/interfaces/group.interface';

interface Props {
  data: ForumGroup;
  dataOther: ForumGroup[];
  dataComment: Comments[];
  updateData?: any;
  page: number;
  setPage: any;
}

const ForumCardDetail: React.FC<Props> = (props) => {
  const { data, updateData, page, setPage, dataOther, dataComment } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorId, setAnchorId] = React.useState<null | HTMLElement>(null);

  const update = true;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setAnchorId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorId(null);
  };

  const handleVisible = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  console.log(dataComment);

  return (
    <>
      <Grid item md={12} xs={12}>
        <Typography className={classes.titlePage}>Informasi Group</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <Card className={classes.root} key={data.id}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <Box display="flex" className={classes.sectionHeader}>
                  <Avatar className={classes.avatarMemberTitle}>
                    <img src={data.owner.profileImage} alt="" />
                  </Avatar>
                  <div className={classes.sectionTitle}>
                    <Typography className={classes.titleGroup}>{data.owner.username}</Typography>
                    <Typography className={classes.createdAt}>
                      {moment(data.createdAt).format('LL')}
                    </Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography className={classes.titleForum}>{data.title}</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <img src={data.thumbnailUrl} alt="" />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography className={classes.descForum}>{data.content}</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box display="flex" justifyContent="end">
                  <div className={classes.iconForum}>
                    <VisibilityOutlinedIcon color="primary" />
                  </div>
                  <Typography>
                    &nbsp;
                    {data.viewsCount}
                    &nbsp;
                  </Typography>
                  <div className={classes.iconForum}>
                    <ThumbUpAltOutlinedIcon color="primary" />
                  </div>
                  <Typography>
                    &nbsp;
                    {data.likesCount}
                    &nbsp;
                  </Typography>
                  <div className={classes.iconForum}>
                    <ChatBubbleOutlineOutlinedIcon color="primary" />
                  </div>
                  <Typography>
                    &nbsp;
                    {data.commentsCount}
                    &nbsp;
                  </Typography>
                  <div className={classes.iconForum}>
                    <ShareOutlinedIcon color="primary" />
                  </div>
                  <Typography>&nbsp; 0 &nbsp;</Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} xs={12}>
        <Card>
          <CardContent>
            {dataComment.length === 0 ? (
              <Typography>Belum ada komentar</Typography>
            ) : (
              <>
                {dataComment.map((row, idx) => (
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Box display="flex" className={classes.sectionHeader}>
                        <Avatar className={classes.avatarMemberTitle}>
                          <img src={row.owner.profileImage} alt="" />
                        </Avatar>
                        <div className={classes.sectionTitle}>
                          <Typography className={classes.titleGroup}>
                            {row.owner.username}
                          </Typography>
                          <Typography className={classes.createdAt}>
                            {moment(row.createdAt).format('LL')}
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Container>
                        <Grid container spacing={1}>
                          <Grid item md={11} xs={10}>
                            <Paper elevation={0} className={classes.paper}>
                              <Typography className={classes.comment}>
                                {row.commentContent}
                              </Typography>
                            </Paper>
                          </Grid>
                          <Grid item md={1} xs={2}>
                            <Box display="flex" justifyContent="end">
                              <IconButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                // onClick={(event) => handleClick(event, groups.id)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                // id={`simple-menu-${groups.id}`}
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                classes={{
                                  paper: classes.paper
                                }}
                              >
                                <MenuItem onClick={handleClose}>
                                  <ListItem
                                    component={RouterLink}
                                    to="/forum-diskusi-detail"
                                    button
                                  >
                                    <ListItemIcon>
                                      <Box display="flex" alignItems="center">
                                        <DeleteIcon fontSize="small" />
                                        <Box marginLeft={1}>
                                          <Typography variant="inherit" noWrap>
                                            Hapus
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </ListItemIcon>
                                  </ListItem>
                                </MenuItem>
                              </Menu>
                            </Box>
                          </Grid>
                          {row.commentReplies.length > 0 ? (
                            <Container>
                              <Grid item md={12}>
                                <Box display="flex">
                                  <ReplyIcon fontSize="small" />
                                  <Typography>
                                    &nbsp;
                                    {row.commentReplies.length}
                                    &nbsp;Balasan
                                  </Typography>
                                  {show === false ? (
                                    <Typography
                                      onClick={handleVisible}
                                      className={classes.showHide}
                                    >
                                      Lihat
                                    </Typography>
                                  ) : (
                                    <Typography onClick={handleHide} className={classes.showHide}>
                                      Sembunyikan
                                    </Typography>
                                  )}
                                </Box>
                              </Grid>
                              {show === false ? (
                                <></>
                              ) : (
                                <Grid item md={12}>
                                  {row.commentReplies.map((rep, idRep) => (
                                    <Grid container spacing={1}>
                                      <Grid item md={12} xs={12}>
                                        <Box display="flex" className={classes.sectionHeader}>
                                          <Avatar className={classes.avatarMemberTitle}>
                                            <img src={rep.owner.profileImage} alt="" />
                                          </Avatar>
                                          <div className={classes.sectionTitle}>
                                            <Typography className={classes.titleGroup}>
                                              {rep.owner.username}
                                            </Typography>
                                            <Typography className={classes.createdAt}>
                                              {moment(rep.createdAt).format('LL')}
                                            </Typography>
                                          </div>
                                        </Box>
                                      </Grid>
                                      <Grid item md={11} xs={10}>
                                        <Paper elevation={0} className={classes.paper}>
                                          <Typography className={classes.comment}>
                                            {rep.commentContent}
                                          </Typography>
                                        </Paper>
                                      </Grid>
                                      <Grid item md={1} xs={2}>
                                        <Box display="flex" justifyContent="end">
                                          <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            // onClick={(event) => handleClick(event, groups.id)}
                                          >
                                            <MoreVertIcon />
                                          </IconButton>
                                          <Menu
                                            // id={`simple-menu-${groups.id}`}
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                            classes={{
                                              paper: classes.paper
                                            }}
                                          >
                                            <MenuItem onClick={handleClose}>
                                              <ListItem
                                                component={RouterLink}
                                                to="/forum-diskusi-detail"
                                                button
                                              >
                                                <ListItemIcon>
                                                  <Box display="flex" alignItems="center">
                                                    <DeleteIcon fontSize="small" />
                                                    <Box marginLeft={1}>
                                                      <Typography variant="inherit" noWrap>
                                                        Hapus
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                </ListItemIcon>
                                              </ListItem>
                                            </MenuItem>
                                          </Menu>
                                        </Box>
                                      </Grid>
                                      <Grid item md={12}>
                                        <Box display="flex">
                                          <ThumbUpAltOutlinedIcon
                                            fontSize="small"
                                            style={{ marginRight: 10 }}
                                          />
                                          <Typography>{rep.likes.length}</Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              )}
                            </Container>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Container>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography className={classes.titlePage}>
          Forum Diskusi Lainnya dari Group Amazing Riyadhoh
        </Typography>
      </Grid>
      <ForumCard data={dataOther} page={page} setPage={setPage} updateData={update} />
    </>
  );
};

export default ForumCardDetail;
