import React, { useState, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { Grid, Typography } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MuiSearchBar from "../../components/MuiSearchBar";
import UserTable from "../../components/UserTable";
import { fetchUsers, destroyUsers, downloadUsers } from "../../actions/users.action";
import * as selector from "../../utils/user/user.selector";
import useStyles from "./ManageUsers.styles";
import MuiButtonIcon from "../../components/MuiButtonIcon";
import MuiSelect from "../../components/MuiSelect";
import DialogFormUsers from "../../components/DialogFormUsers";
import MuiSelectCommunity from "../../components/MuiSelectCommunity";

const statusType = [
  {
    name: "Tidak Aktif"
  },
  {
    name: "Aktif"
  },
];

const roles = [
  {
    name: "Admin"
  },
  {
    name: "SuperAdmin"
  },
];

function ManageUsersPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const usersSearchAndFilter = useSelector(selector.usersSearchAndFilterSelector);
  const [query, setQuery] = useState("");
  const [community, setCommunity] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [dialog, setDialog] = useState({
    open: false,
    data: null
  });
  const [page, setPage] = useState(0);

  const handleQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setQuery(newValue);
    setPage(0);
  };

  const handleCommunity = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setCommunity(newValue);
    setPage(0);
  };

  const handleRole = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setRole(newValue);
    setPage(0);
  };

  const handleStatus = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setStatus(newValue);
    setPage(0);
  };

  const handleDownload = (statusUser: string, communityId: number | string) => {
    dispatch(downloadUsers(statusUser, communityId.toString(), ""));
  };

  useEffect(() => {
    dispatch(fetchUsers());
    return () => {
      dispatch(destroyUsers());
    };
  }, []);

  return (
    <Container>
      <Paper className={classes.paper}>
        <Box marginY={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Kelola Admin</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiSearchBar
                placeholder="Cari nama..."
                width="100%"
                handleChange={handleQuery}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginY={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <MuiSelectCommunity
                name="communityId"
                value={community}
                handleChange={handleCommunity}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiSelect label="Status" value={status} data={statusType} handleChange={handleStatus} />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiSelect label="Role" value={role} data={roles} handleChange={handleRole} />
            </Grid>
          </Grid>
        </Box>
        <Box marginY={4} display="flex" justifyContent="space-between">
          <MuiButtonIcon onClick={() => handleDownload(status, community)} variant="text" color="primary" type="button" disabled={false} icon={<GetAppIcon />}>
            Download User
          </MuiButtonIcon>
          <MuiButtonIcon variant="contained" onClick={() => setDialog({ open: true, data: null })} color="primary" type="button" disabled={false} icon={<AddCircleOutlineIcon />}>
            Tambah User
          </MuiButtonIcon>
        </Box>
        <UserTable
          users={usersSearchAndFilter(query, community, status, "", true, role)}
          isManageUser
          updateData={setDialog}
          page={page}
          setPage={setPage}
        />
      </Paper>
      <DialogFormUsers dialog={dialog} onClose={() => setDialog({ open: false, data: null })} />
    </Container>
  );
}

export default ManageUsersPage;
