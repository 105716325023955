import React, { useState, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { Grid, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MuiSearchBar from "../../components/MuiSearchBar";
import useStyles from "./Broadcast.styles";
import MuiButtonIcon from "../../components/MuiButtonIcon";
import BroadcastTable from "../../components/BroadcastTable";
import { destroyBroadcast, fetchBroadcast } from "../../actions/broadcast.action";
import { broadcastSearchSelector } from "../../utils/broadcast/broadcast.selector";
import DialogFormBroadcast from "../../components/DialogFormBroadcast";

function Broadcast() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const broadcastSearch = useSelector(broadcastSearchSelector);
  const [query, setQuery] = useState("");
  const [dialog, setDialog] = useState({
    open: false,
    data: null
  });
  const [page, setPage] = useState(0);

  const handleQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setQuery(newValue);
    setPage(0);
  };

  useEffect(() => {
    dispatch(fetchBroadcast());
    return () => {
      dispatch(destroyBroadcast());
    };
  }, []);

  return (
    <Container>
      <Paper className={classes.paper}>
        <Box marginY={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Broadcast</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiSearchBar
                placeholder="Cari judul..."
                width="100%"
                handleChange={handleQuery}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginY={4} display="flex" justifyContent="flex-end">
          <MuiButtonIcon variant="contained" onClick={() => setDialog({ open: true, data: null })} color="primary" type="button" disabled={false} icon={<AddCircleOutlineIcon />}>
            Broadcast
          </MuiButtonIcon>
        </Box>
        <BroadcastTable
          broadcasts={broadcastSearch(query)}
          updateData={setDialog}
          page={page}
          setPage={setPage}
        />
      </Paper>
      <DialogFormBroadcast dialog={dialog} onClose={() => setDialog({ open: false, data: null })} />
    </Container>
  );
}

export default Broadcast;
