import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import client from '../api';
import Community from '../utils/interfaces/community.interface';
import * as Actions from '../utils/community/community.action';
import CommunityActionTypes from '../utils/community/community.type';

const fetchCommunityStart = (): Actions.FetchCommunityStartAction => ({
  type: CommunityActionTypes.FETCH_COMMUNITY_START,
  payload: true,
});

const fetchCommunitySuccess = (community: Community[]): Actions.FetchCommunitySuccessAction => ({
  type: CommunityActionTypes.FETCH_COMMUNITY_SUCCESS,
  payload: community,
});

const fetchCommunityFailed = (error: string): Actions.FetchCommunityFailedAction => ({
  type: CommunityActionTypes.FETCH_COMMUNITY_FAILED,
  payload: error,
});

export const fetchCommunity = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchCommunityStart());

    const { data } = await client.get('/community', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    setTimeout(() => {
      dispatch(fetchCommunitySuccess(data.results));
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchCommunityFailed(message));
    console.log(response);
  }
};

export const destroyCommunity = () => ({
  type: CommunityActionTypes.DESTROY_COMMUNITY,
});
