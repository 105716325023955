import React, { ChangeEvent, CSSProperties } from 'react';
import InputBase from '@material-ui/core/InputBase';
import useStyles from './MuiSearchBar.styles';

interface Props {
  width: string | number
  placeholder: string
  handleChange?: (ev: ChangeEvent<HTMLInputElement>) => void
}

const MuiSearchBar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { width, placeholder, handleChange } = props;

  const styles: CSSProperties = {
    height: 55,
    maxWidth: width,
  };

  return (
    <div style={styles} className={classes.search}>
      <div className={classes.searchIcon}>
        <i className="las la-lg la-search" />
      </div>
      <InputBase
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputField,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChange}
      />
    </div>
  );
};

export default MuiSearchBar;
