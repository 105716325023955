import { createSelector } from 'reselect';
import ForumGroupState from '../../utils/forumGroup/forumGroup.state';
import ForumGroup from '../../utils/interfaces/forumGroup.interface';

const forumGroupSelector = (state: any) => state.forumGroup;

export const forumGroupLoadingSelector = createSelector(
  forumGroupSelector,
  (state: ForumGroupState) => state?.isLoading
);

export const forumGroupFetchSelector = createSelector(
  forumGroupSelector,
  (state: ForumGroupState) => state.data
);

export const forumGroupOneFetchSelector = createSelector(
  forumGroupSelector,
  (state: ForumGroupState) => state.dataOne
);

export const forumGroupOtherFetchSelector = createSelector(
  forumGroupSelector,
  (state: ForumGroupState) => state.dataOther
);

export const forumGroupCommentFetchSelector = createSelector(
  forumGroupSelector,
  (state: ForumGroupState) => state.dataComment
);

export const forumSearchSelector = createSelector(
  forumGroupSelector,
  (state: ForumGroupState) => (query: string) => {
    const title = (forumGroup: ForumGroup) =>
      forumGroup?.title?.toLowerCase().includes(query.toLowerCase());
    return state?.data.filter(title);
  }
);

export const forumGroupErrorSelector = createSelector(
  forumGroupSelector,
  (state: ForumGroupState) => state.error
);
