import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import CardContainer from '../CardContainer';
import { DailyPrayers, DailyPrayerTime } from '../../utils/interfaces/prayers/daily.prayer.interface';
import { formatDate } from '../../utils';
import useStyles from './CardPrayerDaily.styles';

interface Props {
  date: string
  dailyPrayers: DailyPrayers[]
}

const prayerTime = (time: DailyPrayerTime): string => {
  switch (time) {
    case 'Fajr':
      return 'Subuh';
    case 'Dhuhr':
      return 'Dzuhur';
    case 'Asr':
      return 'Ashar';
    case 'Maghrib':
      return 'Magrib';
    case 'Isha':
      return 'Isya';
    default:
      return '';
  }
};

const DailyPrayerCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { date, dailyPrayers } = props;
  return (
    <Fragment>
      <CardContainer>
        <Typography className={classes.title} variant="body2">
          {formatDate(date)}
        </Typography>
        <Fragment>
          {dailyPrayers.map((prayer, idx) => (
            <div className={classes.card} key={prayer.id}>
              <Typography variant="body2">
                -
                {' '}
                {prayerTime(prayer.prayerTime)}
              </Typography>
            </div>
          ))}
        </Fragment>
      </CardContainer>
    </Fragment>
  );
};

export default DailyPrayerCard;
