import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Avatar,
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  Divider
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Group from '../../utils/interfaces/group.interface';
import useStyles from './InfoGroupCard.styles';
import arlogo from '../../assets/images/arlogo.png';

interface Props {
  data: Group;
  updateData?: any;
}

const InfoGroupCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { data, updateData } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorId, setAnchorId] = React.useState<null | HTMLElement>(null);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  //   setAnchorId()
  // };
  const handleClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setAnchorId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorId(null);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <Avatar className={classes.avatarCard}>
                <img src={data.imageUrl} alt="" />
              </Avatar>
              <div className={classes.sectionTitle}>
                <Typography className={classes.titleGroup}>{data.name}</Typography>
                <Typography className={classes.createdAt}>
                  Dibuat oleh&nbsp;
                  {data.creator.username}
                  ,&nbsp;
                  {moment(data.createdAt).format('LL')}
                </Typography>
              </div>
            </Grid>
            <Grid item md={12}>
              <br />
            </Grid>
            <Grid item md={6}>
              <Box display="flex">
                <div>
                  <Typography className={classes.titleGroup}>Tipe Group&nbsp;</Typography>
                </div>
                <InfoOutlinedIcon color="primary" fontSize="small" />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box display="flex" justifyContent="flex-end">
                <Typography className={classes.typeGroup}>{data.type}</Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <br />
            </Grid>
            <Grid item md={6}>
              <Box display="flex">
                {data.groupMembership.slice(0, 5).map((imgGroup, idImg) => (
                  <Avatar className={classes.avatarMember} key={idImg}>
                    <img src={imgGroup.member.profileImage} alt="" />
                  </Avatar>
                ))}
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box display="flex" justifyContent="flex-end">
                <Typography className={classes.qtyMember}>
                  <span>{data.membersCount}</span>
                  &nbsp;Anggota
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography className={classes.totalForum}>{data.forumsCount}</Typography>
              <Typography className={classes.qtyMember}>Total forum diskusi</Typography>
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography className={classes.qtyMember}>Max. forum diskusi dapat dibuat</Typography>
              <Typography className={classes.totalForum}>10</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default InfoGroupCard;
