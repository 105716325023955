import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: 8,
    borderColor: `${theme.palette.grey[100]}`
  },
  label: {
    color: "#9FA2B4",
    fontSize: 15,
    marginBottom: 12,
  },
}));

export default useStyles;
