import { push } from "connected-react-router";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import client from "../api";
import Auth from "../utils/interfaces/auth.interface";
import * as Actions from "../utils/auth/auth.action";
import AuthActionTypes from "../utils/auth/auth.type";

const signInStart = (): Actions.SignInStartAction => ({
  type: AuthActionTypes.SIGN_IN_START,
  payload: true,
});

const signInSuccess = (user: string): Actions.SignInSuccessAction => ({
  type: AuthActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

const signInFailed = (error: string): Actions.SignInFailedAction => ({
  type: AuthActionTypes.SIGN_IN_FAILED,
  payload: error,
});

const signOut = () => ({
  type: AuthActionTypes.SIGN_OUT,
});

const login = (auth: Auth) => async (dispatch: Dispatch) => {
  try {
    dispatch(signInStart());

    const { email, password } = auth;
    const { data } = await client.post("/auth/signin", {
      email,
      password,
    });
    if (data.user.isVerified !== "Pending") {
      dispatch(signInSuccess(data.user));
      localStorage.setItem("access_token", data.token);
      dispatch(push("/users"));
      // console.log("b");
    }
    // console.log("a");
    dispatch(signInFailed("Akun Tidak Aktif"));
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(signInFailed(message));
  }
};

export const logout = () => (dispatch: Dispatch) => {
  dispatch(signOut());
  dispatch(push("/login"));
};

export default login;
