import React, { useState, MouseEvent } from "react";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Appbar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import { logout } from "../../actions/auth.action";
import useStyles from "./MuiToolbar.styles";
import arlogo from "../../assets/images/arlogo.png";

function MuiToolbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Appbar className={classes.root} elevation={0} position="sticky">
      <Toolbar>
        <div className={classes.navbar}>
          <Container>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <Box display="flex" style={{ marginTop: "10px" }}>
                  <img width="30px" height="30px" src={arlogo} alt="" />
                  <Typography
                    style={{
                      color: "#2D76F9",
                      fontWeight: 600,
                      marginTop: "5px",
                    }}
                  >
                    &nbsp; Amazing Riyadhoh
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box display="flex" justifyContent="flex-end">
                  <IconButton edge="end" onClick={handleMenu}>
                    <Avatar className={classes.avatar} />
                  </IconButton>
                  <Menu
                    elevation={0}
                    keepMounted
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => {
                        dispatch(logout());
                        handleClose();
                      }}
                    >
                      <Typography variant="body2">Logout</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Toolbar>
    </Appbar>
  );
}

export default MuiToolbar;
