import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContainer from '../CardContainer';
import { formatDate } from '../../utils';
import { ZakatType } from '../../utils/interfaces/zakat/zakat.interface';
import useStyles from './CardZakat.styles';

interface Props {
  date: string
  zakatType: ZakatType
}

function zakat(type: ZakatType): string {
  switch (type) {
    case 'ZakatFitrah':
      return 'Zakat Fitrah';
    case 'ZakatMal':
      return 'Zakat Mal';
    case 'Wakaf':
      return 'Wakaf';
    case 'Infaq':
      return 'Infaq';
    default:
      return '';
  }
}

const CardZakat: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { date, zakatType } = props;
  return (
    <Fragment>
      <CardContainer>
        <Typography className={classes.title} variant="body2">
          {formatDate(date)}
        </Typography>
        <Box marginTop={1}>
          <Typography className={classes.value} variant="body2">
            {zakat(zakatType)}
          </Typography>
        </Box>
      </CardContainer>
    </Fragment>
  );
};

export default CardZakat;
