export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export const formatDate = (date: string): string => {
  const newDate = Date.parse(date);
  const format = new Intl.DateTimeFormat('id-ID', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(newDate);
  return format;
};

export const formatTime = (date: string): string => {
  const newDate = Date.parse(date);
  const format = new Intl.DateTimeFormat('id-ID', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  }).format(newDate);
  return format;
};

export const formatDateTime = (date: string): string => {
  const newDate = Date.parse(date);
  const format = new Intl.DateTimeFormat('id-ID', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  }).format(newDate);
  return format;
};

export const getLastArray = (arr: Array<any>): number => arr.slice(-1)[0];

export const isEmptyString = (val: string) => {
  let result = false;
  if (val === "") {
    result = true;
  }
  return result;
};

export const trimString = (val: string): string => {
  const string = val;
  const length = 100;
  const trimmedString = string.length > length ? `${string.substring(0, length - 3)} ...` : string;
  return trimmedString;
};
