import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@material-ui/core";
import useStyles from "./ReportPanel.styles";
import { ReportStats } from "../../utils/interfaces/reportStats/report.stats.interface";

interface Props {
  data: ReportStats
}

const ReportPanel: React.FC<Props> = (props) => {
  const { data } = props;
  const classes = useStyles();
  return (
    <>
      {/* <Grid container spacing={1}> */}
      <Typography className={classes.title}>Dilaporkan</Typography>
      {/* </Grid> */}
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.accepted}>{data.userReports.accepted}</Typography>
                  <Typography className={classes.normalText}>Pengguna Dilarang</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.ignored}>{data.userReports.declined}</Typography>
                  <Typography className={classes.normalText}>Pengguna Ditoleransi</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.normalText}>
                    <span className={classes.waiting}>{data.userReports.waiting}</span>
                    &nbsp;laporan baru.
                    &nbsp;
                    <a href="/group">Lihat</a>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.accepted}>{data.forumReports.accepted}</Typography>
                  <Typography className={classes.normalText}>Postingan Dilarang</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.ignored}>{data.forumReports.declined}</Typography>
                  <Typography className={classes.normalText}>Postingan Ditoleransi</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.normalText}>
                    <span className={classes.waiting}>{data.forumReports.waiting}</span>
                    &nbsp;laporan baru.
                    &nbsp;
                    <a href="/group">Lihat</a>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.accepted}>
                    {data.commentReports.accepted}
                  </Typography>
                  <Typography className={classes.normalText}>Komentar Dilarang</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.ignored}>
                    {data.commentReports.declined}
                  </Typography>
                  <Typography className={classes.normalText}>Komentar Ditoleransi</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.normalText}>
                    <span className={classes.waiting}>{data.commentReports.waiting}</span>
                    &nbsp;laporan baru.
                    &nbsp;
                    <a href="/group">Lihat</a>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportPanel;
