import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContainer from '../CardContainer';
import { formatDate } from '../../utils';
import useStyles from './CardDzikirRead.styles';

interface Props {
  date: string
  quran: {
    surahName: string
  }
}

const CardDzikirRead: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { date, quran } = props;
  return (
    <Fragment>
      <CardContainer>
        <Typography variant="body2" className={classes.title}>
          {formatDate(date)}
        </Typography>
        <Box marginTop={1}>
          <Typography variant="body2" className={classes.value}>
            {quran.surahName}
          </Typography>
        </Box>
      </CardContainer>
    </Fragment>
  );
};

export default CardDzikirRead;
