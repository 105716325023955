import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2D76F9',
      light: '#DEF7FE',
    },
    secondary: {
      main: '#A2C3FF',
      light: '#F5FDFF',
    },
    grey: {
      50: '#f4f6f8',
      100: '#DDDFE5',
      200: '#929292',
      300: '#63738',
    },
    info: {
      main: '#ffb6b9',
      light: '#fbe8e7',
    },
    success: {
      main: '#46C057',
      light: '#D9F3DC',
    },
    warning: {
      main: '#FF8B00',
      light: '#FFEEDF',
    },
    error: {
      main: '#F73232',
      light: '#FFD5CE',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em',
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey',
        },
      },
    },
    MuiTabs: {
      indicator: {
        height: 0,
        width: 0,
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          borderLeft: '4px solid #2D76F9',
          background: '#DEF7FE',
        },
        '@media only screen and (max-width:600px)': {
          '&$selected': {
            borderLeft: 0,
            borderBottom: '2px solid #2D76F9',
            background: '#DEF7FE',
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
