import React, { useState, ChangeEvent } from 'react';
/** Libs */
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
/** Components */
import TabPanel from '../TabPanel';
import ActivityPrayer from '../ActivityPrayer';
import ActivityFast from '../ActivityFast';
import ActivityZakat from '../ActivityZakat';
import ActivityDzikir from '../ActivityDzikir';
/** Utils */
import { a11yProps } from '../../utils';
/** Styles */
import useStyles from './UserActivity.styles';

function UserActivity() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4} md={3}>
          <Tabs
            orientation={matches ? 'horizontal' : 'vertical'}
            textColor="primary"
            indicatorColor="primary"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
          >
            <Tab
              className={classes.tab}
              label="Sholat"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tab}
              label="Puasa"
              {...a11yProps(1)}
            />
            <Tab
              className={classes.tab}
              label="Zakat"
              {...a11yProps(2)}
            />
            <Tab
              className={classes.tab}
              label="Dzikir"
              {...a11yProps(3)}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <TabPanel value={value} index={0}>
            <ActivityPrayer />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ActivityFast />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ActivityZakat />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ActivityDzikir />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}

export default UserActivity;
