import { createSelector } from "reselect";
import Broadcast from "../interfaces/broadcast.interface";
import BroadcastState from "./broadcast.state";

const broadcastSelector = (state: any) => state.broadcast;

export const broadcastFetchSelector = createSelector(
  broadcastSelector,
  (state: BroadcastState) => state.data
);

export const broadcastSearchSelector = createSelector(
  broadcastSelector,
  (state: BroadcastState) => (query: string) => {
    const title = (broadcast: Broadcast) =>
      broadcast.title.toLowerCase().includes(query.toLowerCase());
    console.log(state);
    return state.data.filter(title);
  }
);

export const broadcastLoadingSelector = createSelector(
  broadcastSelector,
  (state: BroadcastState) => state.isLoading
);
