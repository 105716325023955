import React, { Fragment } from 'react';
/** Libs */
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
/** Components */
import FormLogin from '../../components/FormLogin';
/** Assets */
import LoginIllustration from '../../assets/images/illustration-login.svg';
/** Styles */
import useStyles from './Login.styles';

function Login() {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.root}>
        <Box height="100%" display="flex" alignItems="center">
          <Container>
            <div className={classes.loginContainer}>
              <div className={classes.illustrationContainer}>
                <img className={classes.illustration} src={LoginIllustration} alt="login-illustration" />
              </div>
              <div className={classes.formContainer}>
                <Typography className={classes.textTitle} variant="h6">
                  Sign In
                </Typography>
                <FormLogin />
              </div>
            </div>
          </Container>
        </Box>
      </div>
    </Fragment>
  );
}

export default Login;
