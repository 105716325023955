import ReportStatsActionTypes from "../utils/reportStats/reportStats";
import ReportStatsAction from "../utils/reportStats/reportStats.action";

const initialState = {
  data: {
    userReports: {
      accepted: 0,
      declined: 0,
      waiting: 0,
      total: 0,
    },
    forumReports: {
      accepted: 0,
      declined: 0,
      waiting: 0,
      total: 0,
    },
    commentReports: {
      accepted: 0,
      declined: 0,
      waiting: 0,
      total: 0,
    },
  },
  isLoading: false,
  error: '',
};

const reportStatsReducer = (state = initialState, action: ReportStatsAction) => {
  switch (action.type) {
    case ReportStatsActionTypes.FETCH_REPORT_STATS_START:
      return { ...state, isLoading: true };
    case ReportStatsActionTypes.FETCH_REPORT_STATS_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };
    case ReportStatsActionTypes.FETCH_REPORT_STATS_FAILED:
      return { ...state, isLoading: false, error: action.payload };
    case ReportStatsActionTypes.DESTROY_REPORT_STATS:
      return { ...state, data: initialState.data };
    default:
      return state;
  }
};

export default reportStatsReducer;
