import ForumGroupState from '../utils/forumGroup/forumGroup.state';
import ForumGroupActions from '../utils/forumGroup/forumGroup.action';
import ForumGroupActionTypes from '../utils/forumGroup/forumGroup';
import ForumGroup from '../utils/interfaces/forumGroup.interface';

const dataOne: ForumGroup = {
  id: 0,
  categoryId: 0,
  title: '',
  content: '',
  thumbnailUrl: '',
  createdAt: '',
  viewsCount: 0,
  likesCount: 0,
  commentsCount: 0,
  owner: { id: 0, username: '', profileImage: '' },
  comments: []
};

const initialState: ForumGroupState = {
  data: [],
  dataOne,
  dataComment: [],
  dataOther: [],
  isLoading: false,
  error: ''
};

const forumGroupReducer = (state = initialState, action: ForumGroupActions) => {
  switch (action.type) {
    case ForumGroupActionTypes.FETCH_FORUM_GROUP_START:
      return {
        ...state,
        isLoading: true
      };
    case ForumGroupActionTypes.FETCH_FORUM_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case ForumGroupActionTypes.FETCH_FORUM_GROUP_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataOne: action.payload
      };
    case ForumGroupActionTypes.FETCH_FORUM_GROUP_COMMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataComment: action.payload
      };
    case ForumGroupActionTypes.FETCH_FORUM_GROUP_OTHER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataOther: action.payload
      };
    case ForumGroupActionTypes.FETCH_FORUM_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ForumGroupActionTypes.DESTROY_FORUM_GROUP:
      return {
        ...state,
        data: []
      };
    case ForumGroupActionTypes.DESTROY_FORUM_GROUP_ONE:
      return {
        ...state,
        dataOne
      };
    case ForumGroupActionTypes.DESTROY_FORUM_GROUP_COMMENT:
      return {
        ...state,
        dataComment: []
      };
    case ForumGroupActionTypes.DESTROY_FORUM_GROUP_OTHER:
      return {
        ...state,
        dataOther: []
      };
    default:
      return state;
  }
};

export default forumGroupReducer;
