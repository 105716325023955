import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    fontWeight: 500,
  },
  tableHeading: {
    textAlign: 'left',
  },
  key: {
    color: theme.palette.grey[300],
  },
  value: {
    color: theme.palette.grey[200],
  },
}));

export default useStyles;
