import AuthState from '../utils/auth/auth.state';
import AuthActions from '../utils/auth/auth.action';
import AuthActionTypes from '../utils/auth/auth.type';

const initialState: AuthState = {
  isLoading: false,
  user: '',
  error: '',
};

const authReducer = (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case AuthActionTypes.SIGN_IN_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case AuthActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        user: action.payload,
      };
    case AuthActionTypes.SIGN_IN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case AuthActionTypes.SIGN_OUT:
      return {
        ...state,
        user: '',
        error: '',
      };
    default:
      return state;
  }
};

export default authReducer;
