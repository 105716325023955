import React, { CSSProperties } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useStyles from './CardHeader.styles';

type Color = 'blue' | 'green' | 'orange' | 'pink' | 'red'

interface Props {
  color: Color
  title: string
}

function borderColor(color: Color): string {
  const theme = useTheme();
  switch (color) {
    case 'blue':
      return theme.palette.primary.main;
    case 'green':
      return theme.palette.success.main;
    case 'orange':
      return theme.palette.warning.main;
    case 'pink':
      return theme.palette.info.main;
    case 'red':
      return theme.palette.error.main;
    default:
      return '';
  }
}

const CardHeader: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { color, title } = props;

  const styles: CSSProperties = {
    borderTop: `2px solid ${borderColor(color)}`,
  };

  return (
    <div style={styles} className={classes.root}>
      <Typography className={classes.title} variant="body2">
        {title}
      </Typography>
    </div>
  );
};

export default CardHeader;
