import { createSelector } from 'reselect';
import UserState from '../../utils/user/user.state';
import User, { Status } from '../../utils/interfaces/user.interface';

const usersSelector = (state: any) => state.users;
const authSelector = (state:any) => state.auth;

export const authDataSelector = createSelector(
  authSelector,
  (state) => state.user,
);

export const usersFetchSelector = createSelector(
  usersSelector,
  (state: UserState) => state.users,
);

export const usersFetchStatusSelector = createSelector(
  usersSelector,
  (state: UserState) => state.usersStatus,
);

export const userFetchProfileSelector = createSelector(
  usersSelector,
  (state: UserState) => state.profile,
);

export const userFetchZakatSelector = createSelector(
  usersSelector,
  (state: UserState) => state.profile.zakats,
);

export const userFetchQuranMemoSelector = createSelector(
  usersSelector,
  (state: UserState) => state.profile.quranMemoList,
);

export const userFetchQuranReadSelector = createSelector(
  usersSelector,
  (state: UserState) => state.profile.quranReadList,
);

export const userFetchRamadhanFastSelector = createSelector(
  usersSelector,
  (state: UserState) => state.profile.ramadhanFasts,
);

export const userSunnahFastSelector = createSelector(
  usersSelector,
  (state: UserState) => state.profile.sunnahFasts,
);

export const userDailyPrayers = createSelector(
  usersSelector,
  (state: UserState) => state.profile.userDailyPrayers,
);

export const userRawatibPrayers = createSelector(
  usersSelector,
  (state: UserState) => state.profile.userRawatibPrayers,
);

export const dhuhaPrayers = createSelector(
  usersSelector,
  (state: UserState) => state.profile.dhuhaSunnahPrayers,
);

export const qiyamulPrayers = createSelector(
  usersSelector,
  (state: UserState) => state.profile.qiyamulLailSunnahPrayers,
);

export const otherPrayers = createSelector(
  usersSelector,
  (state: UserState) => state.profile.otherSunnahPrayers,
);

export const usersFilterSelector = createSelector(
  usersSelector,
  (state: UserState) => (status: Status) => state.users
    .filter((user) => user.isVerified === status)
    .map((user) => user),
);

export const usersSearchSelector = createSelector(
  usersSelector,
  (state: UserState) => (query: string) => {
    const username = (user: User) => user.username.toLowerCase().includes(query.toLowerCase());
    return state.users.filter(username);
  },
);

export const usersStatusSearchSelector = createSelector(
  usersSelector,
  (state: UserState) => (query: string) => {
    const username = (user: User) => user.username.toLowerCase().includes(query.toLowerCase());
    return state.usersStatus.filter(username);
  },
);

export const usersSearchAndFilterSelector = createSelector(
  usersSelector,
  (state: UserState) => (query: string,
    community: number | string, status: string, gender: string, isSuperAdmin: boolean,
    role: string) => {
    let statusParam: string;
    if (status === "Tidak Aktif") {
      statusParam = "Pending";
    } else if (status === "Aktif") {
      statusParam = "Active";
    } else {
      statusParam = "";
    }
    const filterUser = (user: User) => user.username.toLowerCase().includes(query.toLowerCase())
    && user?.role?.includes('User')
    && (community !== "" ? user?.community?.id.toString() === community.toString() : user)
    && user.isVerified.includes(statusParam)
    && (gender === "" ? "-" : user?.gender === gender);
    // && user?.gender.includes(gender);
    const filterSuperAdmin = (user: User) =>
      user.username.toLowerCase().includes(query.toLowerCase())
      && (role === "" ? (user?.role?.includes('SuperAdmin') || user?.role?.includes('Admin')) : user?.role === role)
      && (community !== "" ? user?.community?.id.toString().includes(community.toString()) : user)
      && user.isVerified.includes(statusParam);
      // && user?.gender.includes(gender);
    if (isSuperAdmin) {
      return state.users.filter(filterSuperAdmin);
    }
    return state.users.filter(filterUser);
  },
);

export const usersLoadingSelector = createSelector(
  usersSelector,
  (state: UserState) => state.isLoading,
);
