import React, { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { createUsers, editUser } from "../../actions/users.action";
import FormTextField from "../FormTextField";
import MuiSelect from "../MuiSelect";
import MuiSelectCommunity from "../MuiSelectCommunity";
import FormTextFieldPassword from "../FormTextFieldPassword";

interface Props {
  dialog: any
  onClose: any
}

const roles = [
  {
    name: "SuperAdmin"
  },
  {
    name: "Admin"
  },
];

const statusTypes = [
  {
    name: "Tidak Aktif"
  },
  {
    name: "Aktif"
  },
];

const DialogFormUsers: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { dialog, onClose } = props;
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  let validationSchema;
  if (dialog.data === null) {
    validationSchema = yup.object({
      username: yup.string().required("Wajib diisi"),
      role: yup.string().required("Wajib dipilih"),
      communityId: yup.string().required("Wajib dipilih"),
      email: yup.string().required("Wajib diisi").email('Email tidak valid'),
      password: yup.string().required("Wajib diisi").min(8, "Minimal 8 Karakter"),
      confirmationPassword: yup.string().required("Wajib diisi")
        .oneOf([yup.ref("password"), null], "Konfirmasi password berbeda")
        .min(8, "Minimal 8 karakter"),
    });
  } else {
    validationSchema = yup.object({
      username: yup.string().required("Wajib diisi"),
      role: yup.string().required("Wajib dipilih"),
      isVerified: yup.string().required("Wajib dipilih"),
      communityId: yup.string().required("Wajib dipilih"),
      email: yup.string().required("Wajib diisi").email('Email tidak valid'),
    });
  }
  const initialValues: any = {
    email: dialog?.data?.email ?? '',
    username: dialog?.data?.username ?? '',
    role: dialog?.data?.role ?? '',
    isVerified: (dialog?.data?.isVerified === "Pending" ? "Tidak Aktif" : "Aktif") ?? '',
    communityId: dialog?.data?.community?.id ?? '',
    password: '',
    confirmationPassword: '',
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      if (dialog.data === null) {
        dispatch(createUsers(values));
      } else {
        const data = {
          ...values,
          isVerified: values.isVerified === "Tidak Aktif" ? "Pending" : "Active"
        };
        dispatch(editUser(data, dialog.data.id));
      }
      onClose();
    },
  });

  return (
    <Dialog maxWidth="md" fullWidth open={dialog.open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{dialog.data === null ? "Tambah User" : "Edit User"}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormTextField
                name="name"
                type="text"
                placeholder="Masukkan nama"
                label="Nama"
                value={formik.values.username}
                handleChange={formik.handleChange("username")}
                helperText={formik.touched.username && formik.errors.username}
                error={formik.touched.username && Boolean(formik.errors.username)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormTextField
                name="email"
                type="email"
                placeholder="Masukkan email"
                value={formik.values.email}
                label="Email"
                handleChange={formik.handleChange("email")}
                helperText={formik.touched.email && formik.errors.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
            </Grid>
            {dialog.data === null
            && (
              <Fragment>
                <Grid item xs={12} md={6}>
                  <FormTextFieldPassword
                    name="password"
                    showPassword={showNewPassword}
                    setShowPassword={setShowNewPassword}
                    placeholder="Masukkan password"
                    label="Password"
                    value={formik.values.password}
                    handleChange={formik.handleChange("password")}
                    helperText={formik.touched.password && formik.errors.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormTextFieldPassword
                    name="confirmationPassword"
                    showPassword={showConfirmPassword}
                    setShowPassword={setShowConfirmPassword}
                    placeholder="Masukkan konfirmasi password"
                    label="Konfirmasi Password"
                    value={formik.values.confirmationPassword}
                    handleChange={formik.handleChange("confirmationPassword")}
                    helperText={formik.touched.confirmationPassword
                                && formik.errors.confirmationPassword}
                    error={formik.touched.confirmationPassword
                            && Boolean(formik.errors.confirmationPassword)}
                  />
                </Grid>
              </Fragment>
            )}
            <Grid item xs={12} md={6}>
              <MuiSelect
                label="Role"
                name="role"
                value={formik.values.role}
                data={roles}
                handleChange={formik.handleChange("role")}
                helperText={formik.touched.role && formik.errors.role}
                error={formik.touched.role && Boolean(formik.errors.role)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiSelectCommunity
                name="communityId"
                value={formik.values.communityId}
                handleChange={formik.handleChange("communityId")}
                helperText={formik.touched.communityId && formik.errors.communityId}
                error={formik.touched.communityId && Boolean(formik.errors.communityId)}
              />
            </Grid>
            {dialog.data !== null
            && (
            <Grid item xs={12} md={6}>
              <MuiSelect
                label="Status"
                name="isVerified"
                value={formik.values.isVerified}
                data={statusTypes}
                handleChange={formik.handleChange("isVerified")}
                helperText={formik.touched.isVerified && formik.errors.isVerified}
                error={formik.touched.isVerified && Boolean(formik.errors.isVerified)}
              />
            </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="inherit">
            Batal
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogFormUsers;
