import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  avatarCard: {
    border: '1px solid white',
    boxShadow: '0 0 0 1px #2D76F9',
    width: 34,
    height: 34,
    backgroundSize: '100%',
    aspectRatio: '1/1',
    alignItems: 'unset'
  },
  avatarMember: {
    marginRight: -15,
    width: 34,
    height: 34,
    backgroundSize: '100%',
    aspectRatio: '1/1',
    alignItems: 'unset'
  },
  qtyMember: {
    marginLeft: 25,
    marginTop: 10,
    fontSize: '14px',
    '& span': {
      fontWeight: 600,
      color: '#2D76F9'
    }
  },
  createdAt: {
    fontWeight: 400,
    fontSize: '11px',
    color: '#94A5C3'
  },
  titleGroup: {
    fontWeight: 400,
    fontSize: '14px'
  },
  sectionTitle: {
    marginLeft: 10
  },
  sectionMember: {
    marginTop: 14,
    marginBottom: 14
  },
  sectionHeader: {
    marginTop: 14
  },
  length: {
    fontWeight: 400,
    '& span': {
      color: '#2D76F9'
    }
  },
  paper: {
    boxShadow: 'none',
    borderRadius: 8,
    border: '2px solid #E5EAF2'
  }
}));

export default useStyles;
