import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 10,
  },
}));

export default useStyles;
