import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode
  index: number
  value: number
}

const TabPanel: React.FC<Props> = (props) => {
  const {
    children,
    index,
    value,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      { value === index && children}
    </div>
  );
};

export default TabPanel;
