import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: 10,
    background: theme.palette.error.light,
  },
  text: {
    textAlign: 'center',
    color: theme.palette.error.main,
  },
}));

export default useStyles;
