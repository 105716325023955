import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import client from '../api';
import Group from '../utils/interfaces/group.interface';
import * as Actions from '../utils/group/group.action';
import { setAlert } from './toastAlert';
import GroupActionTypes from '../utils/group/group';

const fetchGroupStart = (): Actions.FetchGroupStartAction => ({
  type: GroupActionTypes.FETCH_GROUP_START,
  payload: true
});

const fetchGroupSuccess = (data: Group[]): Actions.FetchGroupSuccessAction => ({
  type: GroupActionTypes.FETCH_GROUP_SUCCESS,
  payload: data
});
const fetchGroupOneSuccess = (dataOne: Group): Actions.FetchGroupOneSuccessAction => ({
  type: GroupActionTypes.FETCH_GROUP_ONE_SUCCESS,
  payload: dataOne
});
const fetchGroupFailed = (error: string): Actions.FetchGroupFailedAction => ({
  type: GroupActionTypes.FETCH_GROUP_FAILED,
  payload: error
});

export const fetchGroup = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchGroupStart());

    const { data } = await client.get('/admin-group', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchGroupSuccess(data.results));
    }, 1000);
    // console.log(fetchGroupSuccess);
  } catch (err: any) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchGroupFailed(message));
  }
};

export const fetchOneGroup = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchGroupStart());

    const { data } = await client.get(`/admin-group/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchGroupOneSuccess(data.data));
    }, 1000);
  } catch (err: any) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchGroupFailed(message));
  }
};

export const deleteGroup = (id: number) => async (dispatch: any) => {
  try {
    dispatch(fetchGroupStart());
    await client.delete(`/admin-group/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    dispatch(fetchGroup());
    dispatch(setAlert('Berhasil menghapus grup', 'success'));
  } catch (err: any) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchGroupFailed(message));
    dispatch(setAlert(message, 'error'));
  }
};

export const destroyGroup = () => ({
  type: GroupActionTypes.DESTROY_GROUP
});

export const destroyGroupOne = () => ({
  type: GroupActionTypes.DESTROY_GROUP_ONE
});
