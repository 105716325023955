import GroupState from '../utils/group/group.state';
import GroupActions from '../utils/group/group.action';
import GroupActionTypes from '../utils/group/group';
import Group from '../utils/interfaces/group.interface';

const dataOne: Group = {
  id: 0,
  activityCount: 0,
  createdAt: '',
  creatorId: 0,
  description: '',
  forumsCount: 0,
  imageUrl: '',
  membersCount: 0,
  name: '',
  type: '',
  specificTargetCount: 0,
  creator: { id: 0, username: '', profileImage: '' },
  groupMembership: [{ id: 0, member: { profileImage: '' } }]
};

const initialState: GroupState = {
  data: [],
  dataOne,
  isLoading: false,
  error: ''
};

const groupReducer = (state = initialState, action: GroupActions) => {
  switch (action.type) {
    case GroupActionTypes.FETCH_GROUP_START:
      return {
        ...state,
        isLoading: true
      };
    case GroupActionTypes.FETCH_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case GroupActionTypes.FETCH_GROUP_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dataOne: action.payload
      };
    case GroupActionTypes.FETCH_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GroupActionTypes.DESTROY_GROUP:
      return {
        ...state,
        data: []
      };
    case GroupActionTypes.DESTROY_GROUP_ONE:
      return {
        ...state,
        dataOne
      };
    default:
      return state;
  }
};

export default groupReducer;
