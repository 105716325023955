import React, { useEffect, useState, ChangeEvent } from 'react';
/** Libs */
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import {
  Grid,
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  Divider,
  Container,
  Breadcrumbs,
  // Link,
  ListItem
} from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { useTheme } from '@material-ui/core/styles';
/** Components */
import MuiSearchBar from '../../components/MuiSearchBar';
import UserActivity from '../../components/UserActivity';
import ForumCard from '../../components/ForumCard';
import useStyles from './ForumGroup.styles';
/** Actions */
import { fetchForumGroup, destroyForumGroup } from '../../actions/forumGroup.action';
import { fetchOneGroup, destroyGroupOne } from '../../actions/group.action';
/** Utils */
import UserProfileRouteParams from '../../utils/interfaces/router.interface';
import { groupOneFetchSelector } from '../../utils/group/group.selector';
import { forumSearchSelector } from '../../utils/forumGroup/forumGroup.selector';
import InfoGroupCard from '../../components/InfoGroupCard';

function ForumGroup() {
  const classes = useStyles();
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const groupOne = useSelector(groupOneFetchSelector);
  const forumGroup = useSelector(forumSearchSelector);
  const { id } = useParams<UserProfileRouteParams>();
  const uid: number = parseInt(id);

  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);

  const handleQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setQuery(newValue);
    setPage(0);
  };

  useEffect(() => {
    dispatch(fetchForumGroup(uid));
    dispatch(fetchOneGroup(uid));
    return () => {
      dispatch(destroyForumGroup());
      dispatch(destroyGroupOne());
    };
  }, []);
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item md={9} sm={8} xs={12}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <ListItem component={RouterLink} to="/group" button>
                  <i className="la la-arrow-left" />
                  &nbsp;Kembali
                </ListItem>
              </Breadcrumbs>
            </Grid>
            <Grid item md={6}>
              <Box display="flex" justifyContent="flex-end" className={classes.sectionTitle}>
                <div>
                  <ChatOutlinedIcon color="primary" />
                </div>
                <Typography className={classes.titlePage}>&nbsp;Forum Diskusi</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <MuiSearchBar placeholder="Cari forum..." width="50%" handleChange={handleQuery} />
          </Grid>
          {/* <Grid item md={12} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Typography className={classes.length}>
                Menampilkan&nbsp;
                <span>10</span>
                &nbsp;dari&nbsp;
                <span>99</span>
              </Typography>
            </Box>
          </Grid> */}
          <br />
          <ForumCard datax={groupOne} data={forumGroup(query)} page={page} setPage={setPage} />
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <InfoGroupCard data={groupOne} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ForumGroup;
