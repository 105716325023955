import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Avatar,
  Box,
  Typography,
  Chip,
  ListItemIcon,
  ListItem,
  Divider,
  TablePagination,
  useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import useStyles from './ForumCard.styles';
import arlogo from '../../assets/images/arlogo.png';
import ForumGroup from '../../utils/interfaces/forumGroup.interface';
import Group from '../../utils/interfaces/group.interface';

interface Props {
  data: ForumGroup[];
  datax?: Group;
  updateData?: any;
  page: number;
  setPage: any;
}

const ForumCard: React.FC<Props> = (props) => {
  const { data, updateData, page, setPage, datax } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorId, setAnchorId] = React.useState<null | HTMLElement>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setAnchorId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorId(null);
  };

  return (
    <>
      {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx) => (
        <Grid item md={12} xs={12} key={row.id}>
          <Card className={classes.root} key={row.id}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={6} xs={6}>
                  <Box display="flex" className={classes.sectionHeader}>
                    <Avatar className={classes.avatarMemberTitle}>
                      <img src={row.owner.profileImage} alt="" />
                    </Avatar>
                    <div className={classes.sectionTitle}>
                      <Typography className={classes.titleGroup}>{row.owner.username}</Typography>
                      <Typography className={classes.createdAt}>
                        {moment(row.createdAt).format('LL')}
                      </Typography>
                    </div>
                  </Box>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Box display="flex" justifyContent="end">
                    <Chip
                      className={classes.sectionChip}
                      size="small"
                      label="Terbaru"
                      color="primary"
                    />
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id={`simple-menu-${row.id}`}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      classes={{
                        paper: classes.paper
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <ListItem
                          component={RouterLink}
                          to={`/forum/detail/${anchorId}/${datax?.id}`}
                        >
                          <ListItemIcon>
                            <Box display="flex" alignItems="center">
                              <SearchIcon fontSize="small" />
                              <Box marginLeft={1}>
                                <Typography variant="inherit" noWrap>
                                  Lihat
                                </Typography>
                              </Box>
                            </Box>
                          </ListItemIcon>
                        </ListItem>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                          <Box display="flex" alignItems="center">
                            <DeleteIcon fontSize="small" />
                            <Box marginLeft={1}>
                              <Typography variant="inherit" noWrap>
                                Hapus
                              </Typography>
                            </Box>
                          </Box>
                        </ListItemIcon>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Divider />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography className={classes.titleForum}>{row.title}</Typography>
                  <Typography className={classes.descForum}>
                    {row.content.slice(0, 500)}
                    {row.content.length > 500 ? '...' : ''}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box display="flex" justifyContent="end">
                    <div className={classes.iconForum}>
                      <VisibilityOutlinedIcon color="primary" />
                    </div>
                    <Typography>
                      &nbsp;
                      {row.viewsCount}
                      &nbsp;
                    </Typography>
                    <div className={classes.iconForum}>
                      <ThumbUpAltOutlinedIcon color="primary" />
                    </div>
                    <Typography>
                      &nbsp;
                      {row.likesCount}
                      &nbsp;
                    </Typography>
                    <div className={classes.iconForum}>
                      <ChatBubbleOutlineOutlinedIcon color="primary" />
                    </div>
                    <Typography>
                      &nbsp;
                      {row.commentsCount}
                      &nbsp;
                    </Typography>
                    <div className={classes.iconForum}>
                      <ShareOutlinedIcon color="primary" />
                    </div>
                    <Typography>&nbsp; 0 &nbsp;</Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {updateData === true ? (
        <></>
      ) : (
        <Grid item xs={12} md={12}>
          <Box justifyContent="center" display="flex">
            <TablePagination
              rowsPerPageOptions={matches ? [] : [10, 20, 30]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default ForumCard;
