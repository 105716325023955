import React, { ReactElement } from 'react';
import useStyles from './CardContainer.styles';

interface Props {
  children: ReactElement | ReactElement[]
}

const CardContainer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { children } = props;

  return <div className={classes.root}>{children}</div>;
};

export default CardContainer;
