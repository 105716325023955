import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import client from '../api';
import ForumGroup from '../utils/interfaces/forumGroup.interface';
import { Comments } from '../utils/interfaces/comments.interface';
import * as Actions from '../utils/forumGroup/forumGroup.action';
import { setAlert } from './toastAlert';
import ForumGroupActionTypes from '../utils/forumGroup/forumGroup';

const fetchForumGroupStart = (): Actions.FetchForumGroupStartAction => ({
  type: ForumGroupActionTypes.FETCH_FORUM_GROUP_START,
  payload: true
});

const fetchForumGroupSuccess = (data: ForumGroup[]): Actions.FetchForumGroupSuccessAction => ({
  type: ForumGroupActionTypes.FETCH_FORUM_GROUP_SUCCESS,
  payload: data
});
const fetchForumGroupOneSuccess = (
  dataOne: ForumGroup
): Actions.FetchForumGroupOneSuccessAction => ({
  type: ForumGroupActionTypes.FETCH_FORUM_GROUP_ONE_SUCCESS,
  payload: dataOne
});
const fetchForumGroupOtherSuccess = (
  dataOther: ForumGroup[]
): Actions.FetchForumGroupOtherSuccessAction => ({
  type: ForumGroupActionTypes.FETCH_FORUM_GROUP_OTHER_SUCCESS,
  payload: dataOther
});
const fetchForumGroupCommentSuccess = (
  dataOther: Comments[]
): Actions.FetchForumGroupCommentSuccessAction => ({
  type: ForumGroupActionTypes.FETCH_FORUM_GROUP_COMMENT_SUCCESS,
  payload: dataOther
});
const fetchForumGroupFailed = (error: string): Actions.FetchForumGroupFailedAction => ({
  type: ForumGroupActionTypes.FETCH_FORUM_GROUP_FAILED,
  payload: error
});

export const fetchForumGroup = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchForumGroupStart());

    const { data } = await client.get(`/admin-group/${id}/forum/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchForumGroupSuccess(data.results));
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchForumGroupFailed(message));
  }
};

export const fetchForumGroupOne = (idGroup: number, id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchForumGroupStart());

    const { data } = await client.get(`/admin-group/${idGroup}/forum/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchForumGroupOneSuccess(data.results));
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchForumGroupFailed(message));
  }
};

export const fetchForumGroupOther = (idGroup: number, id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchForumGroupStart());

    const { data } = await client.get(`/admin-group/${idGroup}/forum/${id}/more-forums`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchForumGroupOtherSuccess(data.data));
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchForumGroupFailed(message));
  }
};

export const fetchComment = (idGroup: number, id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchForumGroupStart());

    const { data } = await client.get(`/admin-group/${idGroup}/forum/${id}/comment?orderBy=popular`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchForumGroupCommentSuccess(data.data));
      console.log(data);
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchForumGroupFailed(message));
  }
};

export const destroyForumGroup = () => ({
  type: ForumGroupActionTypes.DESTROY_FORUM_GROUP
});

export const destroyForumGroupOne = () => ({
  type: ForumGroupActionTypes.DESTROY_FORUM_GROUP_ONE
});
export const destroyForumGroupOther = () => ({
  type: ForumGroupActionTypes.DESTROY_FORUM_GROUP_OTHER
});
export const destroyForumGroupComment = () => ({
  type: ForumGroupActionTypes.DESTROY_FORUM_GROUP_COMMENT
});
