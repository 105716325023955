import { createSelector } from 'reselect';
import ToastAlertState from '../toastAlert/toastAlert.state';

const toastAlert = (state: any) => state.toastAlert;

const toastAlertSelector = createSelector(
  toastAlert,
  (state: ToastAlertState) => state,
);

export default toastAlertSelector;
