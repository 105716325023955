import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: 20,
    textTransform: 'capitalize',
  },
}));

export default useStyles;
