import BroadcastState from '../utils/broadcast/broadcast.state';
import BroadcastActions from '../utils/broadcast/broadcast.action';
import BroadcastActionTypes from '../utils/broadcast/broadcast';

const initialState: BroadcastState = {
  data: [],
  isLoading: false,
  error: '',
};

const broadcastReducer = (state = initialState, action: BroadcastActions) => {
  switch (action.type) {
    case BroadcastActionTypes.FETCH_BROADCAST_START:
      return {
        ...state,
        isLoading: true,
      };
    case BroadcastActionTypes.FETCH_BROADCAST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case BroadcastActionTypes.FETCH_BROADCAST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case BroadcastActionTypes.DESTROY_BROADCAST:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default broadcastReducer;
