import { createSelector } from 'reselect';
import editGroup from '../../utils/editGroup/editGroup.state';

const editGroupSelector = (state: any) => state.editGroup;

export const editGroupLoadingSelector = createSelector(
  editGroupSelector,
  (state: editGroup) => state?.isLoading
);

export const editGroupFetchSelector = createSelector(
  editGroupSelector,
  (state: editGroup) => state.data
);

export const editGroupErrorSelector = createSelector(
  editGroupSelector,
  (state: editGroup) => state.error
);
