import React, { Fragment, useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createUsers, editUser } from '../../actions/users.action';
import { fetchEditGroup, destroyEditGroup, editLimitGroup } from '../../actions/editGroup.action';
import FormTextField from '../FormTextField';
import useStyles from './DialogEditGroup.styles';
import * as selector from '../../utils/editGroup/editGroup.selector';

interface Props {
  dialog: any;
  onClose: any;
}

const DialogEditGroup: React.FC<Props> = (props) => {
  const isLoading = useSelector(selector.editGroupLoadingSelector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { dialog, onClose } = props;
  const validationSchema = yup.object({
    numberLimitPublic: yup.string().required('Wajib diisi'),
    numberLimitPrivate: yup.string().required('Wajib diisi')
  });
  const initialValues: any = {
    numberLimitPublic: dialog?.data?.numberLimitPublic ?? '',
    numberLimitPrivate: dialog?.data?.numberLimitPrivate ?? ''
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      dispatch(editLimitGroup(values));
      // if (dialog.data === null) {
      //   dispatch(createUsers(values));
      // } else {
      //   const data = {
      //     ...values,
      //     isVerified: values.isVerified === "Tidak Aktif" ? "Pending" : "Active"
      //   };
      //   dispatch(editUser(data, dialog.data.id));
      // }
      onClose();
    }
  });

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={dialog.open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.titleDialog}>
        Maksimal Forum Diskusi
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography className={classes.titleDialog}>
                Atur limit maksimal forum diskusi yang dapat dibuat pada setiap group.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormTextField
                name="numberLimitPublic"
                type="number"
                placeholder=""
                value={formik.values.numberLimitPublic}
                label="Limit Maksimal Forum Diskusi Group Publik"
                handleChange={formik.handleChange('numberLimitPublic')}
                helperText={formik.touched.numberLimitPublic && formik.errors.numberLimitPublic}
                error={formik.touched.numberLimitPublic && Boolean(formik.errors.numberLimitPublic)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormTextField
                name="numberLimitPrivate"
                type="number"
                placeholder=""
                value={formik.values.numberLimitPrivate}
                label="Limit Maksimal Forum Diskusi Group Private"
                handleChange={formik.handleChange('numberLimitPrivate')}
                helperText={formik.touched.numberLimitPrivate && formik.errors.numberLimitPrivate}
                error={
                  formik.touched.numberLimitPrivate && Boolean(formik.errors.numberLimitPrivate)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="inherit">
            Batal
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogEditGroup;
