import React, { Fragment } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import MicIcon from '@material-ui/icons/Mic';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import clsx from 'clsx';
import useStyles from './LayoutWithSidebar.styles';
import UserProfile from '../UserProfile';
import User from '../../utils/interfaces/user.interface';
import MuiToastAlert from '../MuiToastAlert';

interface Props {
  children?: any;
  user: User;
}

const LayoutWithSidebar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { children, user } = props;
  const location = useLocation();

  return (
    <Container>
      <MuiToastAlert />
      <Box marginTop={4}>
        <Grid container spacing={2}>
          <Hidden smDown>
            <Grid item xs={12} md={4} lg={3}>
              <Card>
                <CardContent>
                  <Box marginY={3}>
                    <UserProfile
                      profileImage={user.profileImage}
                      username={user.username}
                      email={user.email}
                    />
                  </Box>
                </CardContent>
                <CardContent className={classes.navList}>
                  <List component="nav" aria-label="secondary mailbox folders">
                    <ListItem
                      component={RouterLink}
                      to="/users"
                      button
                      className={clsx(classes.list, {
                        [classes.listActive]: location.pathname === '/users'
                      })}
                    >
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                    {user.role === 'SuperAdmin' && (
                      <Fragment>
                        <ListItem
                          component={RouterLink}
                          to="/manage-users"
                          button
                          className={clsx(classes.list, {
                            [classes.listActive]: location.pathname === '/manage-users'
                          })}
                        >
                          <ListItemIcon>
                            <PersonIcon />
                          </ListItemIcon>
                          <ListItemText primary="Kelola Admin" />
                        </ListItem>
                        <ListItem
                          component={RouterLink}
                          to="/broadcast"
                          button
                          className={clsx(classes.list, {
                            [classes.listActive]: location.pathname === '/broadcast'
                          })}
                        >
                          <ListItemIcon>
                            <MicIcon />
                          </ListItemIcon>
                          <ListItemText primary="Broadcast" />
                        </ListItem>
                        <ListItem
                          component={RouterLink}
                          to="/group"
                          button
                          className={clsx(classes.list, {
                            [classes.listActive]: location.pathname === '/group'
                          })}
                        >
                          <ListItemIcon>
                            <GroupWorkIcon />
                          </ListItemIcon>
                          <ListItemText primary="Group" />
                        </ListItem>
                        {/* <ListItem
                          component={RouterLink}
                          to="/forum-diskusi"
                          button
                          className={clsx(classes.list, {
                            [classes.listActive]:
                              location.pathname === "/forum-diskusi",
                          })}
                        >
                          <ListItemIcon>
                            <ChatBubbleOutlineIcon />
                          </ListItemIcon>
                          <ListItemText primary="Forum Diskusi" />
                        </ListItem> */}
                      </Fragment>
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8} lg={9}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default LayoutWithSidebar;
