import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 10,
    color: theme.palette.grey[200],
  },
  outlinedField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 10,
      '&.Mui-focused fieldset': {
        borderColor: `${theme.palette.secondary.main}`,
        boxShadow: '0px 15px 40px rgba(222, 247, 254, 0.8)',
      },
      '&:hover fieldset': {
        borderColor: `${theme.palette.secondary.main}`,
        boxShadow: '0px 15px 40px rgba(222, 247, 254, 0.8)',
      },
    },
  },
  inputField: {
    fontSize: 14,
    fontWeight: 500,
  },
  adornment: {
    color: theme.palette.grey[200],
  },
  divider: {
    width: 2,
  },
}));

export default useStyles;
