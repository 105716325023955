import User from '../utils/interfaces/user.interface';
import UserState from '../utils/user/user.state';
import UserActions from '../utils/user/user.action';
import UserActionTypes from '../utils/user/user.type';

const profile: User = {
  id: 0,
  username: '',
  community: null,
  email: '',
  phoneNumber: '',
  gender: 'Male',
  isVerified: 'Pending',
  profileImage: '',
  role: 'Admin',
  sunnahFasts: [],
  ramadhanFasts: [],
  zakats: [],
  quranMemoList: [],
  quranReadList: [],
  userDailyPrayers: [],
  userRawatibPrayers: [],
  dhuhaSunnahPrayers: [],
  qiyamulLailSunnahPrayers: [],
  otherSunnahPrayers: [],
};

const initialState: UserState = {
  users: [],
  usersStatus: [],
  profile,
  isLoading: false,
  error: '',
};

const userReducer = (state = initialState, action: UserActions) => {
  switch (action.type) {
    case UserActionTypes.FETCH_USERS_START:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    case UserActionTypes.FETCH_USERS_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersStatus: action.payload,
      };
    case UserActionTypes.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: action.payload,
      };
    case UserActionTypes.FETCH_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UserActionTypes.DESTROY_USERS:
      return {
        ...state,
        users: [],
        usersStatus: [],
      };
    case UserActionTypes.DESTROY_USER_PROFILE:
      return {
        ...state,
        profile,
      };
    default:
      return state;
  }
};

export default userReducer;
