import { createSelector } from "reselect";
import ReportStatsState from "./reportStats.state";

const reportStatsSelector = (state: any) => state.reportStats;

export const reportStatsLoadingSelector = createSelector(
  reportStatsSelector,
  (state: ReportStatsState) => state?.isLoading,
);

export const reportStatsFetchSelector = createSelector(
  reportStatsSelector,
  (state: ReportStatsState) => state.data,
);

export const reportStatsFailedSelector = createSelector(
  reportStatsSelector,
  (state: ReportStatsState) => state.error,
);
