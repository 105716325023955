import User, { Status, Gender } from '../interfaces/user.interface';

export const totalUsers = (users: User[]) => users.length;

export const totalUsersByType = (users: User[], status: Status): number => {
  const results = users.filter((user) => user.isVerified === status);
  return results.length;
};

export const totalUsersByGender = (users: User[], gender: Gender): number => {
  const results = users.filter((user) => user.gender === gender);
  return results.length;
};
