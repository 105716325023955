import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tableHead: {
    display: 'table-header-group',
    background: theme.palette.grey[50],
  },
  tableCellDesktop: {
    display: 'table-cell',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tableCellMobile: {
    paddingLeft: 0,
  },
  tableCell: {
    borderBottom: 'none',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tablePagination: {
    marginTop: theme.spacing(4),
  },
  leftRadius: {
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
  rightRadius: {
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
  text: {
    fontWeight: 500,
    color: theme.palette.grey[300],
  },
  textMobile: {
    display: 'none',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  paper: {
    boxShadow: "none",
    borderRadius: 8,
    border: '2px solid #E5EAF2'
  }
}));

export default useStyles;
