import CommunityState from '../utils/community/community.state';
import CommunityActions from '../utils/community/community.action';
import CommunityActionTypes from '../utils/community/community.type';

const initialState: CommunityState = {
  communities: [],
  isLoading: false,
  error: '',
};

const communityReducer = (state = initialState, action: CommunityActions) => {
  switch (action.type) {
    case CommunityActionTypes.FETCH_COMMUNITY_START:
      return {
        ...state,
        isLoading: true,
      };
    case CommunityActionTypes.FETCH_COMMUNITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        communities: action.payload,
      };
    case CommunityActionTypes.FETCH_COMMUNITY_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CommunityActionTypes.DESTROY_COMMUNITY:
      return {
        ...state,
        communities: [],
      };
    default:
      return state;
  }
};

export default communityReducer;
