import React, { useEffect } from 'react';
import { FormControl, FormHelperText, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as selector from "../../utils/community/community.selector";
import useStyles from './MuiSelectCommunity.styles';
import { destroyCommunity, fetchCommunity } from '../../actions/community.action';

interface Props {
  value: string
  handleChange?: any
  helperText?: any
  error?: any
  name?: string
}

const MuiSelectCommunity: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector(selector.communityFetchSelector);
  const { value, handleChange, error, helperText, name } = props;

  useEffect(() => {
    dispatch(fetchCommunity());
    return () => {
      dispatch(destroyCommunity());
    };
  }, []);

  return (
    <FormControl fullWidth>
      <label id="communityId" className={classes.label}>
        Komunitas
      </label>
      <Select
        className={classes.root}
        variant="outlined"
        labelId="communityId"
        id="communityId"
        value={value}
        fullWidth
        displayEmpty
        onChange={handleChange}
        error={error}
        name={name}
      >
        <MenuItem
          value=""
        >
          Pilih Komunitas
        </MenuItem>
        {data.map((item, index) => (
          <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
      <FormHelperText error>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default MuiSelectCommunity;
