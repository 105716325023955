import React, { Fragment } from 'react';
/** Libs */
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
/** Components */
import CardHeader from '../CardHeader';
import CardWrapper from '../CardWrapper';
import CardSkeleton from '../CardSkeleton';
import RamadhanFast from '../CardFastRamadhan';
import SunnahFast from '../CardFastSunnah';
/** Utils */
import * as selector from '../../utils/user/user.selector';

function ActivityPrayer() {
  const isLoading = useSelector(selector.usersLoadingSelector);
  const ramadhanFast = useSelector(selector.userFetchRamadhanFastSelector);
  const sunnahFast = useSelector(selector.userSunnahFastSelector);

  return (
    <Fragment>
      {isLoading ? <CardSkeleton /> : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="blue" title="Wajib" />
            <CardWrapper>
              {ramadhanFast.map((fast, idx) => (
                <Box key={fast.id} marginTop={idx !== 0 ? 1 : 0}>
                  <RamadhanFast {...fast} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="green" title="Sunnah" />
            <CardWrapper>
              {sunnahFast.map((fast, idx) => (
                <Box key={fast.id} marginTop={idx !== 0 ? 1 : 0}>
                  <SunnahFast {...fast} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default ActivityPrayer;
