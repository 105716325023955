import { createSelector } from 'reselect';
import AuthState from '../../utils/auth/auth.state';

const authSelector = (state: any) => state.auth;

export const authLoadingSelector = createSelector(
  authSelector,
  (state: AuthState) => state.isLoading,
);

export const authUserSelector = createSelector(
  authSelector,
  (state: AuthState) => state.user,
);

export const authErrorSelector = createSelector(
  authSelector,
  (state: AuthState) => state.error,
);
