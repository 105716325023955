import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(4),
  },
  tab: {
    textTransform: 'capitalize',
  },
  icon: {
    height: 20,
    width: 20,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.grey[200],
  },
  card: {
    borderTop: `2px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    padding: theme.spacing(2),
    boxShadow: '0px 16px 40px rgba(125, 125, 125, 0.1)',
  },
}));

export default useStyles;
