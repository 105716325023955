import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  grouped: {
    borderRadius: 8,
    color: "#3D3D3D",
    textTransform: 'capitalize',
  }
}));

export const useStylesButton = makeStyles((theme: Theme) => createStyles({
  selected: {
    color: "white",
    backgroundColor: "#2D76F9"
  }
}));
