import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    height: 220,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 16,
    background: theme.palette.primary.light,
    [theme.breakpoints.down('sm')]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  textContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'center',
    },
  },
  textTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  textSubheader: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  illustrationContainer: {
    height: '100%',
    width: '50%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  illustration: {
    position: 'absolute',
    bottom: 0,
    right: 20,
  },
  containerMobile: {
    height: 220,
    display: 'none',
    background: theme.palette.primary.light,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

export default useStyles;
