import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContainer from '../CardContainer';
import { formatDate } from '../../utils';
import { QuranStatus } from '../../utils/interfaces/dzikir/quran.read.interface';
import useStyles from './CardDzikirMemo.styles';

interface Props {
  date: string
  quran: {
    surahName: string
  }
  status: QuranStatus
}

const CardDzikirMemo: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { date, quran, status } = props;
  return (
    <Fragment>
      <CardContainer>
        <Typography variant="body2" className={classes.title}>
          {formatDate(date)}
        </Typography>
        <Box marginTop={1}>
          <table>
            <tbody>
              <tr>
                <th className={classes.tableHeading}>
                  <Typography variant="body2" className={classes.key}>
                    Surah
                  </Typography>
                </th>
                <td>
                  <Typography variant="body2" className={classes.value}>
                    {quran.surahName}
                  </Typography>
                </td>
              </tr>
              <tr>
                <th className={classes.tableHeading}>
                  <Typography variant="body2" className={classes.key}>
                    Status
                  </Typography>
                </th>
                <td>
                  <Typography variant="body2" className={classes.value}>
                    {status}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </CardContainer>
    </Fragment>
  );
};

export default CardDzikirMemo;
