import React, { Fragment } from 'react';
/** Libs */
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
/** Components */
import CardHeader from '../CardHeader';
import CardWrapper from '../CardWrapper';
import CardSkeleton from '../CardSkeleton';
import CardDzikirMemo from '../CardDzikirMemo';
import CardDzikirRead from '../CardDzikirRead';
/** Utils */
import * as selector from '../../utils/user/user.selector';

function ActivityDzikir() {
  const isLoading = useSelector(selector.usersLoadingSelector);
  const quranMemo = useSelector(selector.userFetchQuranMemoSelector);
  const quranRead = useSelector(selector.userFetchQuranReadSelector);

  return (
    <Fragment>
      {isLoading ? <CardSkeleton /> : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="blue" title="Hafalan" />
            <CardWrapper>
              {quranMemo.map((quran, idx) => (
                <Box key={quran.id} marginTop={idx !== 0 ? 1 : 0}>
                  <CardDzikirMemo key={quran.id} {...quran} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="green" title="Bacaan" />
            <CardWrapper>
              {quranRead.map((quran, idx) => (
                <Box key={quran.id} marginTop={idx !== 0 ? 1 : 0}>
                  <CardDzikirRead key={quran.id} {...quran} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default ActivityDzikir;
