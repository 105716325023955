import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { Snackbar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlert } from '../../actions/toastAlert';
import toastAlertSelector from '../../utils/toastAlert/toastAlert.selector';

const MuiToastAlert = () => {
  const dispatch = useDispatch();
  const toastAlert = useSelector(toastAlertSelector);
  const handleClose = () => {
    dispatch(removeAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={toastAlert.open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert variant="filled" severity={toastAlert.payload?.alertType} onClose={handleClose}>
        <Typography variant="body2">
          {toastAlert.payload?.msg}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default MuiToastAlert;
