import React from 'react';
import { FormHelperText, TextareaAutosize, FormControl } from '@material-ui/core';
import useStyles from './FormTextArea.styles';

interface Props {
  name?: string
  label: string
  placeholder: string
  value: string
  helperText: any
  handleChange?: any
}

const FormTextArea: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    name,
    label,
    placeholder,
    handleChange,
    helperText,
    value
  } = props;

  return (
    <FormControl className={classes.root}>
      <label className={classes.label}>{label}</label>
      <TextareaAutosize
        name={name}
        rowsMin={9}
        placeholder={placeholder}
        autoComplete="off"
        onChange={handleChange}
        defaultValue={value}
      />
      <FormHelperText error>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default FormTextArea;
