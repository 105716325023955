import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { createBroadcast, editBroadcast } from "../../actions/broadcast.action";
import FormTextField from "../FormTextField";
import FormTextArea from "../FormTextArea";

interface Props {
  dialog: any
  onClose: any
}

const DialogFormBroadcast: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { dialog, onClose } = props;
  const validationSchema = yup.object({
    title: yup.string().required("Wajib diisi"),
    description: yup.string().required("Wajib diisi"),
  });
  const initialValues: any = {
    title: dialog?.data?.title ?? '',
    description: dialog?.data?.description ?? '',
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      if (dialog.data === null) {
        dispatch(createBroadcast(values));
      } else {
        dispatch(editBroadcast(values, dialog.data.id));
      }
      onClose();
    },
  });

  return (
    <Dialog maxWidth="md" fullWidth open={dialog.open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{dialog.data === null ? "Tambah Broadcast" : "Edit Broadcast"}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                name="title"
                type="text"
                placeholder="Masukkan judul"
                label="Judul"
                value={formik.values.title}
                handleChange={formik.handleChange("title")}
                helperText={formik.touched.title && formik.errors.title}
                error={formik.touched.title && Boolean(formik.errors.title)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name="description"
                placeholder="Masukkan deskripsi"
                value={formik.values.description}
                label="Deskripsi"
                handleChange={formik.handleChange("description")}
                helperText={formik.touched.description && formik.errors.description}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="inherit">
            Batal
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogFormBroadcast;
