import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Box, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import UserStatus from '../UserStatus';
import UserAvatar from '../UserAvatar';
import CardSkeleton from '../CardSkeleton';
import User from '../../utils/interfaces/user.interface';
import { authDataSelector, usersLoadingSelector } from '../../utils/user/user.selector';
import useStyles from './UserTable.styles';
import { deleteUsers } from '../../actions/users.action';

interface Props {
  users: User[];
  isManageUser: boolean;
  updateData?: any;
  page: number;
  setPage: any;
}

const UserTable: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoading = useSelector(usersLoadingSelector);
  const auth = useSelector(authDataSelector);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { users, isManageUser, updateData, page, setPage } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event: any, user: any) => {
    setAnchorEl(event.currentTarget);
    setData(user);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setData(null);
  };

  const handleDelete = (user: any) => {
    if (window.confirm('Apakah anda yakin?')) dispatch(deleteUsers(user.id));
  };

  const handleUpdateData = (user: any) => {
    updateData({
      open: true,
      data: user
    });
  };

  return (
    <Fragment>
      {isLoading ? (
        <CardSkeleton />
      ) : (
        <Fragment>
          <TableContainer>
            <Table aria-label="user-table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    className={`${classes.tableCell} ${classes.leftRadius}`}
                    colSpan={2}
                    align="left"
                  >
                    <Typography className={classes.text} variant="body2">
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography className={classes.text} variant="body2">
                      Email
                    </Typography>
                  </TableCell>
                  {isManageUser ? (
                    <TableCell className={classes.tableCell} align="left">
                      <Typography className={classes.text} variant="body2">
                        Role
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableCell} align="left">
                      <Typography className={classes.text} variant="body2">
                        Gender
                      </Typography>
                    </TableCell>
                  )}
                  {auth.role === 'SuperAdmin' && (
                    <TableCell className={classes.tableCell} align="left">
                      <Typography className={classes.text} variant="body2">
                        Komunitas
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell className={classes.tableCell} align="left">
                    <Typography className={classes.text} variant="body2">
                      Status
                    </Typography>
                  </TableCell>
                  {isManageUser && (
                    <TableCell className={`${classes.tableCell} ${classes.rightRadius}`}>
                      {' '}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, idx) => (
                    <TableRow key={user.id}>
                      <TableCell
                        className={`${classes.tableCell} ${classes.tableCellDesktop}`}
                        align="left"
                      >
                        <UserAvatar media={user.profileImage} />
                      </TableCell>
                      <TableCell
                        className={`${classes.tableCell} ${classes.tableCellMobile}`}
                        align="left"
                      >
                        <Link component={RouterLink} to={`users/${user.id}`}>
                          {user.username}
                        </Link>
                        <Typography className={classes.textMobile} variant="body2">
                          {user.email}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={`${classes.tableCell} ${classes.tableCellDesktop}`}
                        align="left"
                      >
                        {user.email}
                      </TableCell>
                      {isManageUser ? (
                        <TableCell
                          className={`${classes.tableCell} ${classes.tableCellDesktop}`}
                          align="left"
                        >
                          {user.role}
                        </TableCell>
                      ) : (
                        <TableCell
                          className={`${classes.tableCell} ${classes.tableCellDesktop}`}
                          align="left"
                        >
                          {user.gender ?? '-'}
                        </TableCell>
                      )}
                      {auth.role === 'SuperAdmin' && (
                        <TableCell
                          className={`${classes.tableCell} ${classes.tableCellDesktop}`}
                          align="left"
                        >
                          {user?.community?.name ?? (user?.community?.id === 1 ? 'Tidak Ada Komunitas' : '-')}
                        </TableCell>
                      )}
                      <TableCell
                        className={`${classes.tableCell} ${classes.tableCellDesktop}`}
                        align="left"
                      >
                        <UserStatus status={user.isVerified} />
                      </TableCell>
                      {isManageUser && (
                        <TableCell
                          className={`${classes.tableCell} ${classes.tableCellDesktop}`}
                          align="right"
                        >
                          <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, user)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={`simple-menu-${user.id}`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            classes={{
                              paper: classes.paper
                            }}
                          >
                            <MenuItem onClick={handleClose}>
                              <ListItemIcon>
                                <Box
                                  onClick={() => handleUpdateData(data)}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <EditIcon fontSize="small" />
                                  <Box marginLeft={1}>
                                    <Typography variant="inherit" noWrap>
                                      Edit
                                    </Typography>
                                  </Box>
                                </Box>
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              <ListItemIcon>
                                <Box
                                  onClick={() => handleDelete(data)}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <DeleteIcon fontSize="small" />
                                  <Box marginLeft={1}>
                                    <Typography variant="inherit" noWrap>
                                      Delete
                                    </Typography>
                                  </Box>
                                </Box>
                              </ListItemIcon>
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <TablePagination
            className={classes.tablePagination}
            rowsPerPageOptions={matches ? [] : [5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default UserTable;
