import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    height: "100%",
    wordBreak: "break-all",
  },
  avatar: {
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
}));

export default useStyles;
