import React, { Fragment } from 'react';
/** Libs */
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
/** Components */
import CardHeader from '../CardHeader';
import CardWrapper from '../CardWrapper';
import CardSkeleton from '../CardSkeleton';
import DailyPrayerCard from '../CardPrayerDaily';
import RawatibPrayerCard from '../CardPrayerRawatib';
import DhuhaPrayerCard from '../CardPrayerDhuha';
import QiyamulLailPrayerCard from '../CardQiyamulLail';
import OtherPrayerCard from '../CardPrayerOther';
/** Utils */
import * as selector from '../../utils/user/user.selector';

function ActivityPrayer() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoading = useSelector(selector.usersLoadingSelector);
  const dailyPrayers = useSelector(selector.userDailyPrayers);
  const rawatibPrayers = useSelector(selector.userRawatibPrayers);
  const dhuhaPrayers = useSelector(selector.dhuhaPrayers);
  const qiyamulPrayers = useSelector(selector.qiyamulPrayers);
  const otherPrayers = useSelector(selector.otherPrayers);

  return (
    <Fragment>
      {isLoading ? <CardSkeleton /> : (
        <Grid container spacing={matches ? 4 : 2}>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="blue" title="Wajib" />
            <CardWrapper>
              {dailyPrayers.map((prayer, idx) => (
                <Box key={prayer.id} marginTop={idx !== 0 ? 1 : 0}>
                  <DailyPrayerCard {...prayer} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="green" title="Rawatib" />
            <CardWrapper>
              {rawatibPrayers.map((prayer, idx) => (
                <Box key={prayer.id} marginTop={idx !== 0 ? 1 : 0}>
                  <RawatibPrayerCard {...prayer} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="orange" title="Dhuha" />
            <CardWrapper>
              {dhuhaPrayers.map((prayer, idx) => (
                <Box key={prayer.id} marginTop={idx !== 0 ? 1 : 0}>
                  <DhuhaPrayerCard {...prayer} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="pink" title="Qiyamul Lail" />
            <CardWrapper>
              {qiyamulPrayers.map((prayer, idx) => (
                <Box key={prayer.id} marginTop={idx !== 0 ? 1 : 0}>
                  <QiyamulLailPrayerCard {...prayer} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="red" title="Lainnya" />
            <CardWrapper>
              {otherPrayers.map((prayer, idx) => (
                <Box key={prayer.id} marginTop={idx !== 0 ? 1 : 0}>
                  <OtherPrayerCard {...prayer} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default ActivityPrayer;
