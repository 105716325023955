import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './UserInfo.styles';

interface Props {
  label: string
  value: string
}

const UserInfo: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { label, value } = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.value} variant="body1">
        {value}
      </Typography>
      <Typography className={classes.label} variant="body2">
        {label}
      </Typography>
    </div>
  );
};

export default UserInfo;
