import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import client from '../api';
import User, { Status } from '../utils/interfaces/user.interface';
import * as Actions from '../utils/user/user.action';
import UserActionTypes from '../utils/user/user.type';
import { isEmptyString } from '../utils';
import { setAlert } from './toastAlert';

const fetchUsersStart = (): Actions.FetchUsersStartAction => ({
  type: UserActionTypes.FETCH_USERS_START,
  payload: true
});

const fetchUsersSuccess = (users: User[]): Actions.FetchUsersSuccessAction => ({
  type: UserActionTypes.FETCH_USERS_SUCCESS,
  payload: users
});

export const fetchUsersStatusSuccess = (users: User[]): Actions.FetchUsersStatusSuccessAction => ({
  type: UserActionTypes.FETCH_USERS_STATUS_SUCCESS,
  payload: users
});

export const fetchUserProfileSuccess = (user: User): Actions.FetchUserProfileSuccess => ({
  type: UserActionTypes.FETCH_USER_PROFILE_SUCCESS,
  payload: user
});

const fetchUsersFailed = (error: string): Actions.FetchUsersFailedAction => ({
  type: UserActionTypes.FETCH_USERS_FAILED,
  payload: error
});

export const fetchUsers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchUsersStart());

    const { data } = await client.get('/users', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchUsersSuccess(data.results));
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchUsersFailed(message));
  }
};

export const createUsers = (data: any) => async (dispatch: any) => {
  try {
    dispatch(fetchUsersStart());

    await client.post('/users', data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    dispatch(fetchUsers());
    dispatch(setAlert('Berhasil tambah data', 'success'));
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchUsersFailed(message));
    dispatch(setAlert(message, 'error'));
  }
};

export const editUser = (data: any, id: number) => async (dispatch: any) => {
  try {
    dispatch(fetchUsersStart());

    await client.patch(`/users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    dispatch(fetchUsers());
    dispatch(setAlert('Berhasil edit data', 'success'));
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchUsersFailed(message));
    dispatch(setAlert(message, 'error'));
  }
};

export const deleteUsers = (id: number) => async (dispatch: any) => {
  try {
    dispatch(fetchUsersStart());

    await client.delete(`/users/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    dispatch(fetchUsers());
    dispatch(setAlert('Berhasil hapus data', 'success'));
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchUsersFailed(message));
    dispatch(setAlert(message, 'error'));
  }
};

export const fetchUserProfile = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchUsersStart());

    const { data } = await client.get(`/users/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchUserProfileSuccess(data.results));
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchUsersFailed(message));
  }
};

export const fetchUsersStatus = (status: Status) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchUsersStart());

    const { data } = await client.get(`/users?status=${status}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchUsersStatusSuccess(data.results));
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchUsersFailed(message));
  }
};

export const downloadUsers = (status: string, communityId: string, gender: string) => async () => {
  try {
    let statusParam: string;
    if (status === 'Tidak Aktif') {
      statusParam = 'Pending';
    } else if (status === 'Aktif') {
      statusParam = 'Active';
    } else {
      statusParam = '';
    }
    const { data } = await client.get(
      `/users/download?${!isEmptyString(status) ? `status=${statusParam}&` : ''}${
        !isEmptyString(communityId) ? `communityId=${communityId}&` : ''
      }${!isEmptyString(gender) ? `gender=${gender}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      }
    );
    window.location.href = `${data.results}`;
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
  }
};

export const destroyUsers = () => ({
  type: UserActionTypes.DESTROY_USERS
});

export const destroyUserProfile = () => ({
  type: UserActionTypes.DESTROY_USER_PROFILE
});
