import React, { Fragment } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useStyles from './IllustrationContainer.styles';

interface Props {
  illustration?: any,
  userData?: any
}

const IllustrationContainer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { illustration, userData } = props;

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography className={classes.textTitle} variant="h5">{`Hello, ${userData.username}`}</Typography>
          <Typography className={classes.textSubheader} variant="h6">{`Welcome ${userData.username}`}</Typography>
        </div>
        <Box display={!matches ? 'block' : 'none'}>
          <div className={classes.illustrationContainer}>
            <div className={classes.illustration}>
              <img src={illustration} alt="illustration" height="190" width="250" />
            </div>
          </div>
        </Box>
      </div>
      <div className={classes.containerMobile}>
        <div className={classes.illustrationContainer}>
          <div className={classes.illustration}>
            <img src={illustration} alt="illustration" height="190" width="250" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default IllustrationContainer;
