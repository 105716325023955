import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

function UserProfileSkeleton() {
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" justifyContent="center" marginRight={2}>
        <Skeleton variant="circle" height={50} width={50} />
      </Box>
      <Box>
        <Skeleton variant="text" height={20} width={180} />
        <Skeleton variant="text" height={20} width={100} />
      </Box>
    </Box>
  );
}

export default UserProfileSkeleton;
