import React, { CSSProperties } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Status } from '../../utils/interfaces/user.interface';

interface Props {
  status: Status
}

const UserStatus: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { status } = props;

  const styles: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    borderRadius: 8,
    width: 100,
    background: status === 'Active' ? theme.palette.success.light : theme.palette.warning.light,
    color: status === 'Pending' ? theme.palette.warning.main : theme.palette.success.main,
  };

  return (
    <div style={styles}>
      <Typography variant="body2">{status === 'Pending' ? "Tidak Aktif" : "Aktif"}</Typography>
    </div>
  );
};

export default UserStatus;
