import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import usersReducer from './users.reducer';
import authReducer from './auth.reducer';
import communityReducer from './community.reducer';
import AuthActions from '../utils/auth/auth.action';
import UserActions from '../utils/user/user.action';
import CommunityActions from '../utils/community/community.action';
import toastAlertReducer from './toastAlert.reducer';
import broadcastReducer from './broadcast.reducer';
import BroadcastActions from '../utils/broadcast/broadcast.action';
import groupReducer from './group.reducer';
import GroupActions from '../utils/group/group.action';
import editGroupReducer from './editGroup.reducer';
import EditGroupActions from '../utils/editGroup/editGroup.action';
import ForumGroupActions from '../utils/forumGroup/forumGroup.action';
import forumGroupReducer from './forumGroup.reducer';
import ReportStatsAction from '../utils/reportStats/reportStats.action';
import reportStatsReducer from './reportStats.reducer';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    users: usersReducer,
    community: communityReducer,
    toastAlert: toastAlertReducer,
    broadcast: broadcastReducer,
    group: groupReducer,
    editGroup: editGroupReducer,
    forumGroup: forumGroupReducer,
    reportStats: reportStatsReducer,
  });

export type AppStates = ReturnType<typeof rootReducer>;
export type AppActions =
  | AuthActions
  | UserActions
  | CommunityActions
  | BroadcastActions
  | GroupActions
  | EditGroupActions
  | ForumGroupActions
  | ReportStatsAction;

export default rootReducer;
