import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  normalText: {
    fontSize: '14px',
  },
  ignored: {
    color: '#4CAF50',
    fontWeight: 500,
    fontSize: '20px'
  },
  accepted: {
    color: '#F44336',
    fontWeight: 500,
    fontSize: '20px'
  },
  waiting: {
    color: '#FF9800',
    fontWeight: 500,
  },
  divider: {
    marginTop: 14,
    marginBottom: 14,
  },
  title: {
    marginTop: 14,
    marginBottom: 14,
    fontWeight: 500,
  },
}));

export default useStyles;
