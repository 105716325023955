import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import useStyles from './MuiAlert.styles';

type Severity =
  'error'
  | 'info'
  | 'success'
  | 'warning'

interface Props {
  severity: Severity
  children: string
}

const MuiAlert: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { severity, children } = props;

  return (
    <Alert className={classes.root} severity={severity} icon={false}>
      <Typography className={classes.text} variant="body2">
        {children}
      </Typography>
    </Alert>
  );
};

export default MuiAlert;
