import React, { useEffect } from "react";
/** Libs */
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// import { useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from "@material-ui/core/Container";
// import Avatar from '@material-ui/core/Avatar';
// import Typography from '@material-ui/core/Typography';
/** Components */
import UserActivity from "../../components/UserActivity";
/** Actions */
import {
  fetchUserProfile,
  destroyUserProfile,
} from "../../actions/users.action";
/** Utils */
import UserProfileRouteParams from "../../utils/interfaces/router.interface";
// import { userFetchProfileSelector } from '../../utils/user/user.selector';
/** Styles */
// import useStyles from './Profile.styles';

function Profile() {
  // const classes = useStyles();
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  // const user = useSelector(userFetchProfileSelector);
  const { id } = useParams<UserProfileRouteParams>();
  const uid: number = parseInt(id);

  useEffect(() => {
    dispatch(fetchUserProfile(uid));
    return () => {
      dispatch(destroyUserProfile());
    };
  }, []);

  return (
    <Container>
      <UserActivity />
    </Container>
  );
}

export default Profile;
