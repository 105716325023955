import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 16,
    padding: theme.spacing(4),
    boxShadow: '0px 16px 40px rgba(125, 125, 125, 0.1)',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    padding: theme.spacing(2),
  },
  text: {
    marginLeft: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
    color: theme.palette.grey[200],
  },
  value: {
    fontWeight: 700,
  },
}));

export default useStyles;
