import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { ReportStats } from '../utils/interfaces/reportStats/report.stats.interface';
import client from '../api';
import ReportStatsActionTypes from '../utils/reportStats/reportStats';
import { setAlert } from './toastAlert';
import * as Actions from '../utils/reportStats/reportStats.action';

const fetchReportStatsStart = (): Actions.FetchReportStatsStartAction => ({
  type: ReportStatsActionTypes.FETCH_REPORT_STATS_START,
  payload: true,
});

const fetchReportStatsSuccess = (stats: ReportStats): Actions.FetchReportStatsSuccessAction => ({
  type: ReportStatsActionTypes.FETCH_REPORT_STATS_SUCCESS,
  payload: stats,
});

const fetchReportStatsFailed = (err: string): Actions.FetchReportStatsFailedAction => ({
  type: ReportStatsActionTypes.FETCH_REPORT_STATS_FAILED,
  payload: err
});

export const fetchReportStats = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchReportStatsStart());

    const { data } = await client.get('/admin-report/stats', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    console.log('data.data');
    console.log(data.data);
    setTimeout(() => {
      dispatch(fetchReportStatsSuccess(data.data));
    }, 1000);
  } catch (err: any) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchReportStatsFailed(err));
    dispatch(setAlert(message, 'error'));
  }
};

export const destroyReportStats = () => ({
  type: ReportStatsActionTypes.DESTROY_REPORT_STATS,
});
