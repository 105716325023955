import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography, TextField, Divider } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MuiSearchBar from '../../components/MuiSearchBar';
import useStyles from './Forum.styles';
import MuiButtonIcon from '../../components/MuiButtonIcon';
import BroadcastTable from '../../components/BroadcastTable';
import MuiSelect from '../../components/MuiSelect';
import ForumCard from '../../components/ForumCard';
import { destroyBroadcast, fetchBroadcast } from '../../actions/broadcast.action';
import { broadcastSearchSelector } from '../../utils/broadcast/broadcast.selector';
import DialogFormBroadcast from '../../components/DialogFormBroadcast';

const statusType = [
  {
    name: 'Tidak Aktif'
  },
  {
    name: 'Aktif'
  }
];

function Forum() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const broadcastSearch = useSelector(broadcastSearchSelector);
  const [query, setQuery] = useState('');
  const [status, setStatus] = useState('');
  const [dialog, setDialog] = useState({
    open: false,
    data: null
  });
  const [page, setPage] = useState(0);

  const handleQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setQuery(newValue);
    setPage(0);
  };

  const handleStatus = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setStatus(newValue);
    setPage(0);
  };

  useEffect(() => {
    dispatch(fetchBroadcast());
    return () => {
      dispatch(destroyBroadcast());
    };
  }, []);

  return (
    <Container>
      <Paper className={classes.paper}>
        <Box marginY={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Forum Diskusi</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiSearchBar placeholder="Cari judul..." width="100%" handleChange={handleQuery} />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiSelect label="Group" value="" data={statusType} handleChange={handleStatus} />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiSelect label="Komentar" value="" data={statusType} handleChange={handleStatus} />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiSelect label="Sort" value="" data={statusType} handleChange={handleStatus} />
            </Grid>
            <Grid item xs={12} md={4}>
              <label id="tanggalAwal" className={classes.label}>
                Tanggal Awal
              </label>
              <TextField
                type="date"
                fullWidth
                className={classes.rootDate}
                InputLabelProps={{
                  shrink: true
                }}
                variant="outlined"
                // value={startDate}
                // onChange={(e) => setStart(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label id="tanggalAkhir" className={classes.label}>
                Tanggal Akhir
              </label>
              <TextField
                type="date"
                fullWidth
                className={classes.rootDate}
                InputLabelProps={{
                  shrink: true
                }}
                variant="outlined"
                // value={startDate}
                // onChange={(e) => setStart(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box marginY={1} display="flex" justifyContent="flex-end">
                <Typography className={classes.length}>
                  Menampilkan&nbsp;
                  <span>10</span>
                  &nbsp;dari&nbsp;
                  <span>99</span>
                </Typography>
              </Box>
            </Grid>
            {/* <ForumCard page={page} setPage={setPage} /> */}
          </Grid>
        </Box>
      </Paper>
      <DialogFormBroadcast dialog={dialog} onClose={() => setDialog({ open: false, data: null })} />
    </Container>
  );
}

export default Forum;
