const GroupActionTypes = {
  FETCH_GROUP_START: 'FETCH_GROUP_START',
  FETCH_GROUP_SUCCESS: 'FETCH_GROUP_SUCCESS',
  FETCH_GROUP_ONE_SUCCESS: 'FETCH_GROUP_ONE_SUCCESS',
  FETCH_GROUP_FAILED: 'FETCH_GROUP_FAILED',
  DESTROY_GROUP: 'DESTROY_GROUP',
  DESTROY_GROUP_ONE: 'DESTROY_GROUP_ONE'
};

export default GroupActionTypes;
