import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import UserInfo from '../UserInfo';
import UserProfileSkeleton from '../UserProfileSkeleton';
import { usersLoadingSelector } from '../../utils/user/user.selector';
import useStyles from './UserProfile.styles';

interface Props {
  profileImage: string
  username: string
  email: string
}

const UserProfile: React.FC<Props> = (props) => {
  const classes = useStyles();
  const isLoading = useSelector(usersLoadingSelector);
  const {
    username,
    email,
    profileImage,
  } = props;

  return (
    <Grid item xs={12}>
      {isLoading ? <UserProfileSkeleton /> : (
        <Box
          display="flex"
          alignItems="center"
          textAlign="center"
          padding={0.5}
          className={classes.root}
        >
          <Fragment>
            <Avatar className={classes.avatar} src={profileImage} />
            <Box marginLeft={2} textAlign="left">
              <UserInfo label={email} value={username} />
            </Box>
          </Fragment>
        </Box>
      )}
    </Grid>
  );
};

export default UserProfile;
