import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: 400,
    marginTop: theme.spacing(2),
    overflowY: 'scroll',
  },
}));

export default useStyles;
