import { createSelector } from 'reselect';
import CommunityState from '../community/community.state';

const communitySelector = (state: any) => state.community;

export const communityFetchSelector = createSelector(
  communitySelector,
  (state: CommunityState) => {
    const noCommunity = {
      id: 1,
      name: 'Tidak Ada Komunitas'
    };
    const newObj = [
      noCommunity,
      ...state.communities
    ];
    return newObj;
  }
);

export const communitysLoadingSelector = createSelector(
  communitySelector,
  (state: CommunityState) => state.isLoading,
);
