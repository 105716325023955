import React, { Fragment } from 'react';
/** Libs */
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
/** Components */
import CardHeader from '../CardHeader';
import CardWrapper from '../CardWrapper';
import CardSkeleton from '../CardSkeleton';
import CardZakat from '../CardZakat';
/** Utils */
import * as selector from '../../utils/user/user.selector';

function ActivityZakat() {
  const isLoading = useSelector(selector.usersLoadingSelector);
  const zakats = useSelector(selector.userFetchZakatSelector);

  return (
    <Fragment>
      {isLoading ? <CardSkeleton /> : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <CardHeader color="blue" title="Zakat" />
            <CardWrapper>
              {zakats.map((zakat, idx) => (
                <Box key={zakat.id} marginTop={idx !== 0 ? 1 : 0}>
                  <CardZakat key={zakat.id} {...zakat} />
                </Box>
              ))}
            </CardWrapper>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default ActivityZakat;
