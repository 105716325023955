import React, { ChangeEvent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { IconButton, InputAdornment } from '@material-ui/core';
import useStyles from './FormTextFieldPassword.styles';

interface Props {
  name?: string
  label: string
  placeholder: string
  value: string
  helperText: any
  error: any
  showPassword: boolean
  setShowPassword: any
  handleChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
}

const FormTextFieldPassword: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    name,
    showPassword,
    setShowPassword,
    label,
    placeholder,
    handleChange,
    helperText,
    error,
    value
  } = props;

  return (
    <FormControl className={classes.root}>
      <label className={classes.label}>{label}</label>
      <TextField
        name={name}
        type={showPassword ? "text" : "password"}
        variant="outlined"
        placeholder={placeholder}
        autoComplete="off"
        onChange={handleChange}
        size="medium"
        defaultValue={value}
        error={error}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default FormTextFieldPassword;
