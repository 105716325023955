import React from 'react';
import { FormControl, FormHelperText, Grid, MenuItem, Select, Typography, Box } from '@material-ui/core';
import useStyles from './MuiSelectHorizontal.styles';

type DataType = {
  name: string,
}
interface Props {
  value: string
  label: string
  firstMenu: string
  data: DataType[]
  updateValue?: any
  handleChange?: any
  helperText?: any
  error?: any
  name?: string
}

const MuiSelectHorizontal: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { value, label, firstMenu, data, handleChange, error, helperText, name } = props;

  return (
    <FormControl fullWidth>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Grid xs={4}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid xs={8}>
          <Select
            className={classes.root}
            variant="outlined"
            labelId={label}
            id={label}
            value={value}
            fullWidth
            displayEmpty
            onChange={handleChange}
            error={error}
            name={name}
          >
            <MenuItem
              value=""
            >
              {`Pilih ${firstMenu}`}
            </MenuItem>
            {data.map((item, index) => (
              <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {helperText}
          </FormHelperText>
        </Grid>
      </Box>
    </FormControl>
  );
};

export default MuiSelectHorizontal;
