import React, { Fragment, CSSProperties } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

function CardSkeleton() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const rect: CSSProperties = {
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  };

  return (
    <Fragment>
      {Array.from(new Array(5)).map((_, idx) => (
        <Box key={idx} display="flex" marginTop={2}>
          <Box display={matches ? 'none' : 'block'}>
            <Skeleton style={rect} variant="rect" height={60} width={60} />
          </Box>
          <Box display="column" marginTop={matches ? 2 : 0}>
            <Skeleton variant="text" height={20} width={matches ? 280 : 320} />
            <Skeleton variant="text" height={20} width={220} />
            <Skeleton variant="text" height={20} width={120} />
          </Box>
        </Box>
      ))}
    </Fragment>
  );
}

export default CardSkeleton;
