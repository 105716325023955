import React, { useEffect, useState, ChangeEvent } from 'react';
/** Libs */
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import {
  Grid,
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  Divider,
  Container,
  Breadcrumbs,
  // Link,
  ListItem
} from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { useTheme } from '@material-ui/core/styles';
/** Components */
import MuiSearchBar from '../../components/MuiSearchBar';
import UserActivity from '../../components/UserActivity';
import ForumCard from '../../components/ForumCard';
import useStyles from './ForumDetail.styles';
/** Actions */
import {
  fetchForumGroupOne,
  destroyForumGroupOne,
  fetchForumGroupOther,
  destroyForumGroupOther,
  fetchComment,
  destroyForumGroupComment
} from '../../actions/forumGroup.action';
import { fetchOneGroup, destroyGroupOne } from '../../actions/group.action';
/** Utils */
import ForumGroupOneRouteParams from '../../utils/interfaces/routerGroup.interface';
import { groupOneFetchSelector } from '../../utils/group/group.selector';
import {
  forumGroupOneFetchSelector,
  forumGroupOtherFetchSelector,
  forumGroupCommentFetchSelector
} from '../../utils/forumGroup/forumGroup.selector';
import InfoGroupCard from '../../components/InfoGroupCard';
import ForumCardDetail from '../../components/ForumCardDetail';

function ForumDetail() {
  const classes = useStyles();
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const groupOne = useSelector(groupOneFetchSelector);
  const forumGroupOne = useSelector(forumGroupOneFetchSelector);
  const forumGroupOther = useSelector(forumGroupOtherFetchSelector);
  const forumGroupComment = useSelector(forumGroupCommentFetchSelector);
  const { id } = useParams<ForumGroupOneRouteParams>();
  const { idGroup } = useParams<ForumGroupOneRouteParams>();
  const uid: number = parseInt(id);
  const uidGroup: number = parseInt(idGroup);

  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);

  const handleQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setQuery(newValue);
    setPage(0);
  };

  useEffect(() => {
    dispatch(fetchForumGroupOne(uidGroup, uid));
    dispatch(fetchForumGroupOther(uidGroup, uid));
    dispatch(fetchComment(uidGroup, uid));
    dispatch(fetchOneGroup(uidGroup));
    return () => {
      dispatch(destroyForumGroupOne());
      dispatch(destroyForumGroupOther());
      dispatch(destroyForumGroupComment());
      dispatch(destroyGroupOne());
    };
  }, []);

  console.log(forumGroupComment);

  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item md={9} sm={8} xs={12}>
          <Grid item md={12} xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <ListItem component={RouterLink} to={`/forum-diskusi/${uidGroup}`} button>
                <i className="la la-arrow-left" />
                &nbsp;Kembali
              </ListItem>
            </Breadcrumbs>
          </Grid>
          <br />
          <ForumCardDetail
            data={forumGroupOne}
            dataOther={forumGroupOther}
            dataComment={forumGroupComment}
            page={page}
            setPage={setPage}
          />
        </Grid>
        <Grid item md={3} sm={4} xs={12}>
          <InfoGroupCard data={groupOne} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ForumDetail;
