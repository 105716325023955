import * as Actions from '../utils/toastAlert/toastAlert.action';
import ToastAlertActionTypes from '../utils/toastAlert/toastAlert.type';

export const setAlert = (msg: string, alertType: string): Actions.SetAlert => ({
  type: ToastAlertActionTypes.SET_ALERT,
  payload: { msg, alertType },
});

export const removeAlert = (): Actions.RemoveAlert => ({
  type: ToastAlertActionTypes.REMOVE_ALERT,
  payload: null,
});
