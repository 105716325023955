import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContainer from '../CardContainer';
import { formatDate } from '../../utils';
import useStyles from './CardFastRamadhan.styles';

interface Props {
  date: string
  isFasting: boolean
  notes: string
}

const CardFastRamadhan: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { date, isFasting, notes } = props;

  return (
    <Fragment>
      <CardContainer>
        <Typography className={classes.title} variant="body2">
          {formatDate(date)}
        </Typography>
        <Box marginTop={1}>
          <Typography className={classes.value} variant="body2">
            {isFasting ? 'Puasa' : 'Tidak Puasa'}
          </Typography>
        </Box>
        <Box marginTop={isFasting ? 0 : 1}>
          <Typography className={classes.value} variant="body2">
            {notes}
          </Typography>
        </Box>
      </CardContainer>
    </Fragment>
  );
};

export default CardFastRamadhan;
