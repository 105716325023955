import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 16,
    padding: theme.spacing(4),
    boxShadow: '0px 16px 40px rgba(125, 125, 125, 0.1)',
  },
}));

export default useStyles;
