import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navList: {
      padding: 0,
    },
    list: {
      marginBottom: theme.spacing(2),
      "&:hover": {
        backgroundColor: "#F8F8F8",
        borderRight: `3px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main
      }
    },
    listActive: {
      borderRight: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: "#F8F8F8",
      color: theme.palette.primary.main
    }
  }));

export default useStyles;
