import React, { Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authDataSelector } from './utils/user/user.selector';
import PrivateRoute from './routes/PrivateRoute';
import MuiToolbar from './components/MuiToolbar';
import LayoutWithSidebar from './components/LayoutWithSidebar';
import LoginPage from './pages/Login';
import UsersPage from './pages/Users';
import ManageUsersPage from './pages/ManageUsers';
import ProfilePage from './pages/Profile';
import BroadcastPage from './pages/Broadcast';
import GroupPage from './pages/Group';
import ForumPage from './pages/Forum';
import ForumGroupPage from './pages/ForumGroup';
import ForumDetailPage from './pages/ForumDetail';

function App() {
  const { pathname } = useLocation();
  const user = useSelector(authDataSelector);

  return (
    <Fragment>
      {pathname.match('/login') ? null : <MuiToolbar />}
      <Redirect from="/" to="/users" />
      <Switch>
        <Route path="/login" render={() => <LoginPage />} />
        <PrivateRoute exact path="/users">
          <LayoutWithSidebar user={user}>
            <UsersPage />
          </LayoutWithSidebar>
        </PrivateRoute>
        <PrivateRoute exact path="/manage-users">
          <LayoutWithSidebar user={user}>
            <ManageUsersPage />
          </LayoutWithSidebar>
        </PrivateRoute>
        <PrivateRoute exact path="/broadcast">
          <LayoutWithSidebar user={user}>
            <BroadcastPage />
          </LayoutWithSidebar>
        </PrivateRoute>
        <PrivateRoute exact path="/group">
          <LayoutWithSidebar user={user}>
            <GroupPage />
          </LayoutWithSidebar>
        </PrivateRoute>
        <PrivateRoute exact path="/forum-diskusi">
          <LayoutWithSidebar user={user}>
            <ForumPage />
          </LayoutWithSidebar>
        </PrivateRoute>
        <PrivateRoute path="/users/:id">
          <ProfilePage />
        </PrivateRoute>
        <PrivateRoute path="/forum-diskusi/:id">
          <ForumGroupPage />
        </PrivateRoute>
        <PrivateRoute path="/forum/detail/:id/:idGroup">
          <ForumDetailPage />
        </PrivateRoute>
      </Switch>
    </Fragment>
  );
}

export default App;
