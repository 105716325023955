import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import useStyles from './MuiContainedButton.styles';

type Color = 'inherit' | 'primary' | 'secondary' | 'default'
type Type = 'button' | 'submit' | 'reset' | undefined

interface Props {
  type: Type
  color: Color
  disabled?: boolean
  children: string | ReactNode
}

const MuiContainedButton: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    type,
    color,
    disabled,
    children,
  } = props;

  return (
    <Button
      className={classes.root}
      type={type}
      size="large"
      variant="contained"
      color={color}
      disabled={disabled}
      disableElevation
    >
      {children}
    </Button>
  );
};

export default MuiContainedButton;
