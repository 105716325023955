import React, { CSSProperties, ReactNode } from "react";
import { Button } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import useStyles from './FilterBubble.styles';

interface Props {
    children: string | ReactNode,
    handleClick?: any
}

const FilterBubble : React.FC<Props> = (props) => {
  const { children, handleClick } = props;
  const iconStyles: CSSProperties = {
    opacity: "75%"
  };
  const buttonStyles = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      classes={buttonStyles}
      disableElevation
      onClick={handleClick}
      endIcon={(
        <CancelIcon
          style={iconStyles}
        />
      )}
    >
      {children}
    </Button>
  );
};

export default FilterBubble;
