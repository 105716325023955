import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./UserCard.styles";

type Color = "Blue" | "Green" | "Orange";

interface Props {
  icon: string;
  color: Color;
  title: string;
  value: number;
}

const iconContainerColor = (backgroundColor: Color): string => {
  const theme = useTheme();
  switch (backgroundColor) {
    case "Blue":
      return theme.palette.primary.light;
    case "Green":
      return theme.palette.success.light;
    case "Orange":
      return theme.palette.warning.light;
    default:
      return "";
  }
};

const iconColor = (color: Color): string => {
  const theme = useTheme();
  switch (color) {
    case "Blue":
      return theme.palette.primary.main;
    case "Green":
      return theme.palette.success.main;
    case "Orange":
      return theme.palette.warning.main;
    default:
      return "";
  }
};

const UserCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { icon, color, title, value } = props;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper className={classes.root} elevation={0}>
        <Box display="flex" alignItems="center">
          <div
            className={classes.iconContainer}
            style={{ background: iconContainerColor(color) }}
          >
            <i
              className={`las la-2x la-${icon}`}
              style={{ color: iconColor(color) }}
            />
          </div>
          <div className={classes.text}>
            <Typography className={classes.title} variant="body1">
              {title}
            </Typography>
            <Typography className={classes.value} variant="body1">
              {value}
            </Typography>
          </div>
        </Box>
      </Paper>
    </Grid>
  );
};

export default UserCard;
