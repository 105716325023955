import React from 'react';
import { Location } from 'history';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authUserSelector } from '../utils/auth/auth.selector';

interface Props extends RouteProps {
}

const PrivateRoute: React.FC<Props> = (props) => {
  const user = useSelector(authUserSelector);
  const { children, ...rest } = props;

  const GuardedRoute = (location: Location) => {
    if (user) {
      return children;
    }

    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  };

  return <Route {...rest} render={({ location }) => GuardedRoute(location)} />;
};

export default PrivateRoute;
