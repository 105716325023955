import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContainer from '../CardContainer';
import { formatDate } from '../../utils';
import { FastType } from '../../utils/interfaces/fasts/sunnah.fast.interface';
import useStyles from './CardFastSunnah.styles';

interface Props {
  date: string
  fastType: FastType
}

function fastingType(type: FastType): string {
  switch (type) {
    case 'AshuraFasting':
      return 'Puasa Asyura';
    case 'ArafahFasting':
      return 'Puasa Arafah';
    case 'MondayThrusdayFasting':
      return 'Puasa Senin Kamis';
    case 'DawoodFasting':
      return 'Puasa Daud';
    case 'AyyamulBidhFasting':
      return 'Puasa Ayyamul Bidh';
    case 'ShawwalFasting':
      return 'Puasa Syawal';
    default:
      return '';
  }
}

const CardFastRamadhan: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { date, fastType } = props;

  return (
    <Fragment>
      <CardContainer>
        <Typography className={classes.title} variant="body2">
          {formatDate(date)}
        </Typography>
        <Box marginTop={1}>
          <Typography className={classes.value} variant="body2">
            {fastingType(fastType)}
          </Typography>
        </Box>
      </CardContainer>
    </Fragment>
  );
};

export default CardFastRamadhan;
