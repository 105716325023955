import React, { useState, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import IllustrationContainer from "../../components/IllustrationContainer";
import MuiSearchBar from "../../components/MuiSearchBar";
import UserCard from "../../components/UserCard";
import UserDonutChart from "../../components/UserDonutChart";
import UserTable from "../../components/UserTable";
import { totalUsersByType, totalUsers } from "../../utils/user";
import {
  fetchUsers,
  destroyUsers,
  downloadUsers,
} from "../../actions/users.action";
import * as selector from "../../utils/user/user.selector";
import useStyles from "./Users.styles";

import AdminIllustration from "../../assets/images/desk-illustration.svg";
import MuiSelect from "../../components/MuiSelect";
import MuiButtonIcon from "../../components/MuiButtonIcon";
import MuiSelectCommunity from "../../components/MuiSelectCommunity";

const statusType = [
  {
    name: "Tidak Aktif",
  },
  {
    name: "Aktif",
  },
];

const genders = [
  {
    name: "Male",
  },
  {
    name: "Female",
  },
];

function UsersPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const usersSearchAndFilter = useSelector(
    selector.usersSearchAndFilterSelector
  );
  const authData = useSelector(selector.authDataSelector);
  const [query, setQuery] = useState("");
  const [community, setCommunity] = useState("");
  const [status, setStatus] = useState("");
  const [gender, setGender] = useState("");
  const [page, setPage] = useState(0);

  const handleCommunity = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setCommunity(newValue);
    setPage(0);
  };

  const handleStatus = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setStatus(newValue);
    setPage(0);
  };

  const handleGender = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setGender(newValue);
    setPage(0);
  };

  const handleQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setQuery(newValue);
    setPage(0);
  };

  const handleDownload = (
    statusUser: string,
    communityId: number | string,
    genderFilter: string
  ) => {
    dispatch(downloadUsers(statusUser, communityId.toString(), genderFilter));
  };

  useEffect(() => {
    dispatch(fetchUsers());
    return () => {
      dispatch(destroyUsers());
    };
  }, []);

  return (
    <Container>
      <Box marginBottom={4}>
        <IllustrationContainer
          illustration={AdminIllustration}
          userData={authData}
        />
      </Box>
      <Grid container spacing={2}>
        <UserCard
          color="Green"
          icon="user-check"
          title="Active Users"
          value={totalUsersByType(
            usersSearchAndFilter(query, community, status, gender, false, ""),
            "Active"
          )}
        />
        <UserCard
          color="Orange"
          icon="user-clock"
          title="Pending Users"
          value={totalUsersByType(
            usersSearchAndFilter(query, community, status, gender, false, ""),
            "Pending"
          )}
        />
        <UserCard
          color="Blue"
          icon="user-friends"
          title="Total Users"
          value={totalUsers(
            usersSearchAndFilter(query, community, status, gender, false, "")
          )}
        />
      </Grid>
      <Box marginY={4}>
        <Grid container spacing={2}>
          <UserDonutChart
            users={usersSearchAndFilter(
              query,
              community,
              status,
              gender,
              false,
              ""
            )}
          />
        </Grid>
      </Box>
      <Paper className={classes.paper}>
        <Box marginY={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Users JFOA Mobile Apps</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiSearchBar
                placeholder="Cari nama..."
                width="100%"
                handleChange={handleQuery}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginY={4}>
          <Grid container spacing={2}>
            {authData.role === "SuperAdmin" && (
              <Grid item xs={12} md={4}>
                <MuiSelectCommunity
                  name="communityId"
                  value={community}
                  handleChange={handleCommunity}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <MuiSelect
                label="Status"
                value={status}
                data={statusType}
                handleChange={handleStatus}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MuiSelect
                label="Gender"
                value={gender}
                data={genders}
                handleChange={handleGender}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginY={4} display="flex" justifyContent="flex-end">
          <MuiButtonIcon
            onClick={() => handleDownload(status, community, gender)}
            variant="text"
            color="primary"
            type="button"
            disabled={false}
            icon={<GetAppIcon />}
          >
            Download User
          </MuiButtonIcon>
        </Box>
        <UserTable
          page={page}
          setPage={setPage}
          users={usersSearchAndFilter(
            query,
            community,
            status,
            gender,
            false,
            ""
          )}
          isManageUser={false}
        />
      </Paper>
    </Container>
  );
}

export default UsersPage;
