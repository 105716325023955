import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import client from '../api';
import editGroup from '../utils/interfaces/editGroup.interface';
import * as Actions from '../utils/editGroup/editGroup.action';
import { setAlert } from './toastAlert';
import EditGroupActionTypes from '../utils/editGroup/editGroup';

const fetchEditGroupStart = (): Actions.FetchEditGroupStartAction => ({
  type: EditGroupActionTypes.FETCH_EDIT_GROUP_START,
  payload: true
});

const fetchEditGroupSuccess = (data: string): Actions.FetchEditGroupSuccessAction => ({
  type: EditGroupActionTypes.FETCH_EDIT_GROUP_SUCCESS,
  payload: data
});
const fetchEditGroupFailed = (error: string): Actions.FetchEditGroupFailedAction => ({
  type: EditGroupActionTypes.FETCH_EDIT_GROUP_FAILED,
  payload: error
});

export const fetchEditGroup = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchEditGroupStart());

    const { data } = await client.get('/admin-group/forum-limits', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    setTimeout(() => {
      dispatch(fetchEditGroupSuccess(data.data));
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchEditGroupFailed(message));
  }
};

export const editLimitGroup = (data: any) => async (dispatch: any) => {
  try {
    dispatch(fetchEditGroupStart());

    await client.patch('/admin-group/forum-limits', data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    });
    dispatch(fetchEditGroup());
    dispatch(setAlert('Berhasil mengatur maksimal forum diskusi', 'success'));
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchEditGroupFailed(message));
    dispatch(setAlert(message, 'error'));
  }
};

export const destroyEditGroup = () => ({
  type: EditGroupActionTypes.DESTROY_EDIT_GROUP
});
