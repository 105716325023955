import EditGroupState from '../utils/editGroup/editGroup.state';
import EditGroupActions from '../utils/editGroup/editGroup.action';
import EditGroupActionTypes from '../utils/editGroup/editGroup';

const initialState: EditGroupState = {
  isLoading: false,
  data: '',
  error: ''
};

const editGroupReducer = (state = initialState, action: EditGroupActions) => {
  switch (action.type) {
    case EditGroupActionTypes.FETCH_EDIT_GROUP_START:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case EditGroupActionTypes.FETCH_EDIT_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        data: action.payload
      };
    case EditGroupActionTypes.FETCH_EDIT_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case EditGroupActionTypes.DESTROY_EDIT_GROUP:
      return {
        ...state,
        data: ''
      };
    default:
      return state;
  }
};

export default editGroupReducer;
