import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 500,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 14
  },
  text: {
    fontSize: 14
  }
}));

export default useStyles;
