import React, { ReactNode } from "react";
import Button from "@material-ui/core/Button";
import useStyles from "./MuiButtonIcon.styles";

type Color = "inherit" | "primary" | "secondary" | "default";
type Type = "button" | "submit" | "reset" | undefined;
type Variant = "contained" | "text" | "outlined"

interface Props {
  type: Type;
  color: Color;
  disabled?: boolean;
  children: string | ReactNode;
  variant: Variant;
  icon: any;
  onClick?: any;
}

const MuiButtonIcon: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { type, color, disabled, children, variant, icon, onClick } = props;

  return (
    <Button
      className={classes.root}
      type={type}
      variant={variant}
      size="large"
      color={color}
      startIcon={icon}
      disabled={disabled}
      disableElevation
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default MuiButtonIcon;
