import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 16,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: '0px 16px 40px rgba(125, 125, 125, 0.1)'
  },
  tab: {
    textTransform: 'capitalize'
  },
  label: {
    color: '#9FA2B4',
    fontSize: 15
  },
  rootDate: {
    borderRadius: 8,
    marginTop: 12,
    borderColor: `${theme.palette.grey[100]}`
  },
  length: {
    fontWeight: 400,
    marginTop: 20,
    marginBottom: 20,
    '& span': {
      color: '#2D76F9'
    }
  },
  root: {
    marginTop: 40
  },
  titlePage: {
    color: '#2D76F9'
  },
  sectionTitle: {
    marginTop: 20,
    marginBottom: 20
  }
}));

export default useStyles;
