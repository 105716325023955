import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  label: {
    fontWeight: 400,
    color: theme.palette.grey[200],
  },
  value: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
  },
}));

export default useStyles;
