import ToastAlertState from '../utils/toastAlert/toastAlert.state';
import ToastAlertActions from '../utils/toastAlert/toastAlert.action';
import ToastAlertActionTypes from '../utils/toastAlert/toastAlert.type';

const initialState: ToastAlertState = {
  open: false,
  payload: null
};

const ToastAlertReducer = (state = initialState, action: ToastAlertActions) => {
  switch (action.type) {
    case ToastAlertActionTypes.SET_ALERT:
      return {
        ...state,
        open: true,
        payload: action.payload
      };
    case ToastAlertActionTypes.REMOVE_ALERT:
      return {
        ...state,
        open: false,
        payload: null
      };
    default:
      return state;
  }
};

export default ToastAlertReducer;
