import { createSelector } from 'reselect';
import Group from '../../utils/interfaces/group.interface';
import GroupState from '../../utils/group/group.state';

const groupSelector = (state: any) => state.group;

export const groupFetchSelector = createSelector(groupSelector, (state: GroupState) => state.data);

export const groupOneFetchSelector = createSelector(
  groupSelector,
  (state: GroupState) => state.dataOne
);

export const groupSearchSelector = createSelector(
  groupSelector,
  (state: GroupState) => (query: string, type: string, sortBy: string, daysAge: number = 0) => {
    const now = new Date();
    const createdAtLimit = new Date(new Date().setDate(now.getDate() - daysAge));
    const filterGroup = (group: Group) => {
      if (daysAge === 0) {
        return group.name.toLowerCase().includes(query.toLowerCase())
          && group.type.includes(type);
      }
      return group.name.toLowerCase().includes(query.toLowerCase())
        && group.type.includes(type)
        && new Date(group.createdAt) >= createdAtLimit;
    };
    const groups = state.data.filter(filterGroup);
    const latestSort = (a: Group, b: Group) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    const oldestSort = (a: Group, b: Group) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    const nameAzSort = (a: Group, b: Group) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (b.name.toLowerCase() < a.name.toLowerCase()) return 1;
      return 0;
    };
    const nameZaSort = (a: Group, b: Group) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
      if (b.name.toLowerCase() > a.name.toLowerCase()) return 1;
      return 0;
    };
    if (sortBy === 'Terbaru') return groups.sort(latestSort);
    if (sortBy === 'Terlama') return groups.sort(oldestSort);
    if (sortBy === 'Judul A-Z') return groups.sort(nameAzSort);
    if (sortBy === 'Judul Z-A') return groups.sort(nameZaSort);
    return groups.sort(latestSort);
  }
);

export const groupLoadingSelector = createSelector(
  groupSelector,
  (state: GroupState) => state.isLoading
);
