import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Box, ListItemIcon, Menu, MenuItem, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import CardSkeleton from '../CardSkeleton';
import useStyles from './BroadcastTable.styles';
import { deleteBroadcast, sendBroadcast } from '../../actions/broadcast.action';
import Broadcast from '../../utils/interfaces/broadcast.interface';
import { broadcastLoadingSelector } from '../../utils/broadcast/broadcast.selector';
import BroadcastStatus from '../BroadcastStatus';
import { formatDateTime, trimString } from '../../utils';

interface Props {
  broadcasts: Broadcast[]
  updateData?: any
  page: number,
  setPage: any
}

const BroadcastTable: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoading = useSelector(broadcastLoadingSelector);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { broadcasts, updateData, page, setPage } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event: any, broadcast: any) => {
    setAnchorEl(event.currentTarget);
    setData(broadcast);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setData(null);
  };

  const handleDelete = (broadcast: any) => {
    if (window.confirm('Apakah anda yakin?'))dispatch(deleteBroadcast(broadcast.id));
  };

  const handleUpdateData = (broadcast: any) => {
    updateData({
      open: true,
      data: broadcast
    });
  };

  const handleSendBroadcast = (broadcast: any) => {
    dispatch(sendBroadcast(broadcast.id));
  };

  return (
    <Fragment>
      {isLoading ? <CardSkeleton /> : (
        <Fragment>
          <TableContainer>
            <Table aria-label="broadcast-table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    align="left"
                  >
                    <Typography className={classes.text} variant="body2">
                      Tanggal Update
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography className={classes.text} variant="body2">
                      Judul
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography className={classes.text} variant="body2">
                      Deskripsi
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography className={classes.text} variant="body2">
                      Distribusi
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    <Typography className={classes.text} variant="body2">
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell className={`${classes.tableCell} ${classes.rightRadius}`}>
                    <Typography className={classes.text} variant="body2">
                      Action
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {broadcasts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((broadcast, idx) => (
                    <TableRow key={broadcast.id}>
                      <TableCell className={classes.tableCell} align="left">
                        {formatDateTime(broadcast.updatedAt)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {broadcast.title}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <Tooltip title={broadcast.description}>
                          <Typography variant="body2">
                            {trimString(broadcast.description)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {broadcast.sendCount}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <BroadcastStatus status={broadcast.status} />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClick(event, broadcast)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id={`simple-menu-${broadcast.id}`}
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          classes={{
                            paper: classes.paper
                          }}
                        >
                          <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                              <Box onClick={() => handleSendBroadcast(data)} display="flex" alignItems="center">
                                <SendIcon fontSize="small" />
                                <Box marginLeft={1}>
                                  <Typography variant="inherit" noWrap>Send</Typography>
                                </Box>
                              </Box>
                            </ListItemIcon>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                              <Box onClick={() => handleUpdateData(data)} display="flex" alignItems="center">
                                <EditIcon fontSize="small" />
                                <Box marginLeft={1}>
                                  <Typography variant="inherit" noWrap>Edit</Typography>
                                </Box>
                              </Box>
                            </ListItemIcon>
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                              <Box onClick={() => handleDelete(data)} display="flex" alignItems="center">
                                <DeleteIcon fontSize="small" />
                                <Box marginLeft={1}>
                                  <Typography variant="inherit" noWrap>Delete</Typography>
                                </Box>
                              </Box>
                            </ListItemIcon>
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <TablePagination
            className={classes.tablePagination}
            rowsPerPageOptions={matches ? [] : [5, 10, 25]}
            component="div"
            count={broadcasts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default BroadcastTable;
