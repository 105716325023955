import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import MuiTextField from '../MuiTextField';
import MuiContainedButton from '../MuiContainedButton';
import MuiAlert from '../MuiAlert/MuiAlert';
import login from '../../actions/auth.action';
import * as selector from '../../utils/auth/auth.selector';

export interface Values {
  email: string
  password: string
}

const Login = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const isLoading = useSelector(selector.authLoadingSelector);
  const error = useSelector(selector.authErrorSelector);

  const initialValues: Values = {
    email: '', password: '',
  };

  return (
    <Fragment>
      {error && <Box width={matches ? 280 : 320} marginTop={2}><MuiAlert severity="error">{error}</MuiAlert></Box>}
      <Formik
        initialValues={initialValues}
        onSubmit={(values: Values) => {
          dispatch(login(values));
        }}
      >
        {({ handleChange }) => (
          <Form>
            <Box width={matches ? 280 : 320} marginTop={2}>
              <MuiTextField
                name="email"
                type="email"
                placeholder="Email Address"
                label="Email Address"
                icon="la-envelope"
                handleChange={handleChange}
              />
            </Box>
            <Box width={matches ? 280 : 320} marginTop={2}>
              <MuiTextField
                name="password"
                type="password"
                placeholder="Password"
                icon="la-lock"
                label="Password"
                handleChange={handleChange}
              />
            </Box>
            <Box width={matches ? 280 : 320} marginTop={4}>
              <MuiContainedButton type="submit" color="primary" disabled={isLoading}>
                {isLoading ? <CircularProgress size={30} /> : 'Sign In'}
              </MuiContainedButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default Login;
