import React from 'react';
import Avatar from '@material-ui/core/Avatar';

interface Props {
  media: string
}

const UserAvatar: React.FC<Props> = (props) => {
  const { media } = props;

  return <Avatar src={media} alt="user-avatar" />;
};

export default UserAvatar;
