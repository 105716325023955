import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CardContainer from '../CardContainer';
import { DhuhaPrayerType } from '../../utils/interfaces/prayers/dhuha.prayer.interface';
import { formatDate } from '../../utils';
import useStyles from './CardPrayerDhuha.styles';

interface Props {
  date: string
  prayerType: DhuhaPrayerType
}

function prayer(type: DhuhaPrayerType): string {
  switch (type) {
    case 'TwoRakat':
      return '2 Rakaat';
    case 'FourRakat':
      return '4 Rakaat';
    case 'SixRakat':
      return '6 Rakaat';
    case 'EightRakat':
      return '8 Rakaat';
    case 'TenRakat':
      return '10 Rakaat';
    case 'TwelveRakat':
      return '12 Rakaat';
    default:
      return '';
  }
}

const DhuhaPrayerCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { date, prayerType } = props;
  return (
    <Fragment>
      <CardContainer>
        <Typography className={classes.title} variant="body2">
          {formatDate(date)}
        </Typography>
        <Box marginTop={1}>
          <Typography className={classes.value} variant="body2">
            {prayer(prayerType)}
          </Typography>
        </Box>
      </CardContainer>
    </Fragment>
  );
};

export default DhuhaPrayerCard;
