import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  search: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 16,
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  searchIcon: {
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    pointerEvents: 'none',
  },
  inputRoot: {
    fontSize: 14,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: 'inherit',
  },
  inputField: {
    width: '100%',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(6)}px)`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default useStyles;
