import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Box, Container, IconButton } from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import MuiSearchBar from '../../components/MuiSearchBar';
import useStyles from './Group.styles';
import MuiButtonIcon from '../../components/MuiButtonIcon';
import GroupCard from '../../components/GroupCard';
import GroupFiltersCard from '../../components/GroupFiltersCard';

import { fetchGroup, destroyGroup } from '../../actions/group.action';
import { fetchEditGroup, destroyEditGroup } from '../../actions/editGroup.action';
import { fetchReportStats, destroyReportStats } from '../../actions/reportStats.action';
import { groupSearchSelector } from '../../utils/group/group.selector';
import { editGroupFetchSelector } from '../../utils/editGroup/editGroup.selector';
import { reportStatsFetchSelector } from '../../utils/reportStats/reportStats.selector';
import DialogEditGroup from '../../components/DialogEditGroup';
import FilterBubbleGroup from '../../components/FilterBubbleGroup';
import ReportPanel from '../../components/ReportPanel';

function Group() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const groupSearch = useSelector(groupSearchSelector);
  const editGroupData = useSelector(editGroupFetchSelector);
  const reportStats = useSelector(reportStatsFetchSelector);
  console.log('reportStats');
  console.log(reportStats);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('');
  const [groupType, setGroupType] = useState('');
  const [daysAgo, setDaysAgo] = useState(0);
  const [page, setPage] = useState(0);
  const [dialog, setDialog] = useState({
    open: false,
    data: null
  });
  const [filterOpen, setFilterOpen] = useState(false);

  const handleQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setQuery(newValue);
    setPage(0);
  };

  useEffect(() => {
    dispatch(fetchGroup());
    dispatch(fetchEditGroup());
    dispatch(fetchReportStats());
    return () => {
      dispatch(destroyGroup());
      dispatch(destroyEditGroup());
      dispatch(destroyReportStats());
    };
  }, []);

  return (
    <Container>
      {/* <Paper className={classes.paper}> */}
      <Box marginY={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Group Amazing Riyadhoh</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="flex-end">
              <MuiButtonIcon
                variant="contained"
                onClick={() => setDialog({ open: true, data: editGroupData })}
                color="primary"
                type="button"
                disabled={false}
                icon={<SettingsIcon />}
              >
                Pengaturan
              </MuiButtonIcon>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ReportPanel data={reportStats} />
          </Grid>
          <Grid item xs={12} sm={11}>
            <MuiSearchBar placeholder="Cari group..." width="100%" handleChange={handleQuery} />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Box display="flex" justifyContent="flex-end" alignItems="" mt={1}>
              <Hidden only={["sm", "md"]}>
                <MuiButtonIcon
                  variant="text"
                  onClick={() => setFilterOpen(!filterOpen)}
                  color="primary"
                  type="button"
                  disabled={false}
                  icon={filterOpen ? <CloseIcon /> : <TuneIcon />}
                >
                  {filterOpen ? "Tutup" : "Filter"}
                </MuiButtonIcon>
              </Hidden>
              <Hidden only={["xs", "lg", "xl"]}>
                <IconButton
                  color="primary"
                  onClick={() => setFilterOpen(!filterOpen)}
                >
                  {filterOpen ? <CloseIcon /> : <TuneIcon />}
                </IconButton>
              </Hidden>
            </Box>
          </Grid>
          {filterOpen
            ? (
              <GroupFiltersCard
                groupTypeInit={groupType}
                daysAgoInit={daysAgo}
                updateDaysAgo={setDaysAgo}
                updateGroupType={setGroupType}
                updatePage={setPage}
              />
            ) : (
              <FilterBubbleGroup
                groupType={groupType}
                daysAgo={daysAgo}
                updateGroupType={setGroupType}
                updateDaysAgo={setDaysAgo}
                updatePage={setPage}
              />
            )}
          <GroupCard
            page={page}
            setPage={setPage}
            group={groupSearch(query, groupType, sort, daysAgo)}
            updateSort={setSort}
          />
        </Grid>
      </Box>

      {/* </Paper> */}
      <DialogEditGroup dialog={dialog} onClose={() => setDialog({ open: false, data: null })} />
    </Container>
  );
}

export default Group;
