const UserActionTypes = {
  FETCH_USERS_START: 'FETCH_USERS_START',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_STATUS_SUCCESS: 'FETCH_USERS_STATUS_SUCCESS',
  FETCH_USER_PROFILE_SUCCESS: 'FETCH_USER_PROFILE_SUCCESS',
  FETCH_USERS_FAILED: 'FETCH_USERS_FAILED',
  DESTROY_USER_PROFILE: 'DESTROY_USER_PROFILE',
  DESTROY_USERS: 'DESTROY_USERS',
};

export default UserActionTypes;
