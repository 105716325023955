import React from 'react';
import { FormControl, FormHelperText, MenuItem, Select } from '@material-ui/core';
import useStyles from './MuiSelect.styles';

type DataType = {
  name: string
}
interface Props {
  value: string
  label: string
  data: DataType[]
  handleChange?: any
  helperText?: any
  error?: any
  name?: string
}

const MuiSelect: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { value, label, data, handleChange, error, helperText, name } = props;

  return (
    <FormControl fullWidth>
      <label id={label} className={classes.label}>
        {label}
      </label>
      <Select
        className={classes.root}
        variant="outlined"
        labelId={label}
        id={label}
        value={value}
        fullWidth
        displayEmpty
        onChange={handleChange}
        error={error}
        name={name}
      >
        <MenuItem
          value=""
        >
          {`Pilih ${label}`}
        </MenuItem>
        {data.map((item, index) => (
          <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
        ))}
      </Select>
      <FormHelperText error>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default MuiSelect;
