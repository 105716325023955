import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import client from '../api';
import Broadcast from '../utils/interfaces/broadcast.interface';
import * as Actions from '../utils/broadcast/broadcast.action';
import { setAlert } from './toastAlert';
import BroadcastActionTypes from '../utils/broadcast/broadcast';

const fetchBroadcastStart = (): Actions.FetchBroadcastStartAction => ({
  type: BroadcastActionTypes.FETCH_BROADCAST_START,
  payload: true,
});

const fetchBroadcastSuccess = (data: Broadcast[]): Actions.FetchBroadcastSuccessAction => ({
  type: BroadcastActionTypes.FETCH_BROADCAST_SUCCESS,
  payload: data,
});
const fetchBroadcastFailed = (error: string): Actions.FetchBroadcastFailedAction => ({
  type: BroadcastActionTypes.FETCH_BROADCAST_FAILED,
  payload: error,
});

export const fetchBroadcast = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchBroadcastStart());

    const { data } = await client.get('/broadcast', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    setTimeout(() => {
      dispatch(fetchBroadcastSuccess(data.results));
    }, 1000);
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchBroadcastFailed(message));
  }
};

export const createBroadcast = (data: any) => async (dispatch: any) => {
  try {
    dispatch(fetchBroadcastStart());

    await client.post('/broadcast', data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch(fetchBroadcast());
    dispatch(setAlert("Berhasil tambah data", "success"));
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchBroadcastFailed(message));
    dispatch(setAlert(message, "error"));
  }
};

export const editBroadcast = (data: any, id: number) => async (dispatch: any) => {
  try {
    dispatch(fetchBroadcastStart());

    await client.patch(`/broadcast/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch(fetchBroadcast());
    dispatch(setAlert("Berhasil edit data", "success"));
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchBroadcastFailed(message));
    dispatch(setAlert(message, "error"));
  }
};

export const sendBroadcast = (id: number) => async (dispatch: any) => {
  try {
    dispatch(fetchBroadcastStart());

    await client.patch(`/broadcast/send/${id}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch(fetchBroadcast());
    dispatch(setAlert("Berhasil kirim broadcast", "success"));
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchBroadcastFailed(message));
    dispatch(setAlert(message, "error"));
  }
};

export const deleteBroadcast = (id: number) => async (dispatch: any) => {
  try {
    dispatch(fetchBroadcastStart());

    await client.delete(`/broadcast/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch(fetchBroadcast());
    dispatch(setAlert("Berhasil hapus data", "success"));
  } catch (err) {
    const response = err.response as AxiosResponse;
    const { message }: { message: string } = response.data;
    dispatch(fetchBroadcastFailed(message));
    dispatch(setAlert(message, "error"));
  }
};

export const destroyBroadcast = () => ({
  type: BroadcastActionTypes.DESTROY_BROADCAST,
});
