import React from 'react';
import Chart, { Props } from 'react-apexcharts';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { totalUsersByGender } from '../../utils/user';
import useStyles from './UserDonutChart.styles';

const UserDonutChart: React.FC<Props> = (props) => {
  const { users } = props;
  const classes = useStyles();
  const theme = useTheme();
  const male: number = totalUsersByGender(users, 'Male');
  const female: number = totalUsersByGender(users, 'Female');

  const config: Props = {
    options: {
      chart: {
        type: 'donut',
        fontFamily: 'Poppins',
      },
      series: [male, female],
      labels: ['Male', 'Female'],
      colors: [theme.palette.primary.main, theme.palette.secondary.main],
    },
  };

  return (
    <Grid item xs={12} md={6}>
      <Paper className={classes.root} elevation={0}>
        <Chart
          type="donut"
          height={320}
          options={config.options}
          series={config.options?.series}
        />
      </Paper>
    </Grid>
  );
};

export default UserDonutChart;
