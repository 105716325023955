import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography
} from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import MuiToggleButtonGroup from '../MuiToggleButtonGroup';
import useStyles from './GroupFiltersCard.styles';

interface Props {
    handleChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
    groupTypeInit: string;
    daysAgoInit: number;
    updateGroupType: any;
    updateDaysAgo: any;
    updatePage: any;
}
const groupTypes: {
  name: string,
  value: any
}[] = [
  {
    name: 'Public',
    value: 'Public'
  },
  {
    name: 'Private',
    value: 'Private'
  },
];
const daysAgoList: {
  name: string,
  value: any
}[] = [
  {
    name: '7 Hari',
    value: 7
  },
  {
    name: '14 Hari',
    value: 14
  },
  {
    name: "1 Bulan",
    value: 30
  },
  {
    name: "3 Bulan",
    value: 90
  }
];

const GroupFiltersCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { groupTypeInit, daysAgoInit, updateDaysAgo, updateGroupType, updatePage } = props;
  const [groupType, setGroupType] = React.useState(groupTypeInit || '');
  const [daysAgo, setDaysAgo] = React.useState(daysAgoInit || 0);

  const handleGroupType = (event: React.MouseEvent<HTMLElement>, value: string) => {
    const valueToUpdate = value === null ? '' : value;
    setGroupType(valueToUpdate);
    updateGroupType(valueToUpdate);
    updatePage(0);
  };
  const handleDaysAgo = (event: React.MouseEvent<HTMLElement>, value: number) => {
    const valueToUpdate = value === null ? 0 : value;
    setDaysAgo(valueToUpdate);
    updateDaysAgo(valueToUpdate);
    updatePage(0);
  };
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.title}>Filter</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Tipe Grup</Typography>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start" marginTop={1}>
                  <MuiToggleButtonGroup
                    value={groupType}
                    data={groupTypes}
                    exclusive
                    orientation="horizontal"
                    onChange={handleGroupType}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Terakhir Dibuat</Typography>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start" marginTop={1}>
                  <MuiToggleButtonGroup
                    value={daysAgo}
                    data={daysAgoList}
                    exclusive
                    orientation="horizontal"
                    onChange={handleDaysAgo}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default GroupFiltersCard;
