import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import CardContainer from '../CardContainer';
import { formatDate } from '../../utils';
import { RawatibPrayers, RawatibPrayerTime } from '../../utils/interfaces/prayers/rawatib.prayer.interface';
import useStyles from './CardPrayerRawatib.styles';

interface Props {
  date: string
  rawatibPrayers: RawatibPrayers[]
}

const prayerTime = (prayer: RawatibPrayerTime): string => {
  switch (prayer) {
    case 'TwoRakatBeforeFajr':
      return '2 Rakaat Sebelum Subuh';
    case 'TwoRakatBeforeDhuhr':
      return '2 Rakaat Sebelum Dzuhur';
    case 'FourRakatBeforeDhuhr':
      return '4 Rakaat Setelah Dzuhur';
    case 'TwoRakatAfterDhuhr':
      return '2 Rakaat Setelah Dzuhur';
    case 'TwoRakatBeforeAsr':
      return '2 Rakaat Sebelum Ashar';
    case 'FourRakatBeforeAsr':
      return '4 Rakaat Sebelum Ashar';
    case 'TwoRakatAfterMaghrib':
      return '2 Rakaat Setelah Maghrib';
    case 'TwoRakatAfterIsha':
      return '2 Rakaat Setelah Isya';
    default:
      return '';
  }
};

const CardRawatibPrayer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { date, rawatibPrayers } = props;

  return (
    <Fragment>
      <CardContainer>
        <Typography className={classes.title} variant="body2">
          {formatDate(date)}
        </Typography>
        <Fragment>
          {rawatibPrayers.map((prayer, idx) => (
            <div className={classes.card} key={prayer.id}>
              <Typography variant="body2">
                -
                {' '}
                {prayerTime(prayer.prayerTime)}
              </Typography>
            </div>
          ))}
        </Fragment>
      </CardContainer>
    </Fragment>
  );
};

export default CardRawatibPrayer;
