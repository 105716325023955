import React from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import FilterBubble from "../FilterBubble/FilterBubble";

interface Props {
    groupType: string;
    daysAgo: number;
    updateGroupType: any;
    updateDaysAgo: any;
    updatePage: any;
}

const FilterBubbleGroup : React.FC<Props> = (props) => {
  const { groupType, daysAgo, updateGroupType, updateDaysAgo, updatePage } = props;
  const handleClearGroupType = () => {
    updateGroupType('');
    updatePage(0);
  };
  const handleClearDaysAgo = () => {
    updateDaysAgo(0);
    updatePage(0);
  };
  const handleClearAll = () => {
    updateDaysAgo(0);
    updateGroupType('');
    updatePage(0);
  };
  return (
    <Grid item xs={12}>
      {groupType !== '' && (
        <FilterBubble
          handleClick={handleClearGroupType}
        >
          {groupType}
        </FilterBubble>
      )}
      {' '}
      {daysAgo !== 0 && (
        <FilterBubble
          handleClick={handleClearDaysAgo}
        >
          {`${daysAgo} Hari`}
        </FilterBubble>
      )}
      {' '}
      {(groupType !== '' || daysAgo !== 0) && (
        <Button
          variant="text"
          size="small"
          color="primary"
          disableRipple
          onClick={handleClearAll}
        >
          Hapus semua
        </Button>
      )}
    </Grid>
  );
};

export default FilterBubbleGroup;
