import React, { ChangeEvent } from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import useStyles from './MuiTextField.styles';

interface PropsAdornment {
  icon?: string
}

const MuiInputAdornment: React.FC<PropsAdornment> = (props) => {
  const classes = useStyles();
  const { icon } = props;

  return (
    <InputAdornment className={classes.adornment} position="start">
      <Box display="flex">
        <i className={icon} />
        <Box marginX={1}>
          <Divider className={classes.divider} orientation="vertical" />
        </Box>
      </Box>
    </InputAdornment>
  );
};

interface Props {
  type: string
  name?: string
  label: string
  icon?: string
  placeholder: string
  handleChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
}

const MuiTextField: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    name,
    type,
    label,
    icon,
    placeholder,
    handleChange,
  } = props;

  return (
    <FormControl className={classes.root}>
      <label className={classes.label}>{label}</label>
      <TextField
        className={classes.outlinedField}
        name={name}
        type={type}
        variant="outlined"
        placeholder={placeholder}
        autoComplete="off"
        InputProps={{
          startAdornment: <MuiInputAdornment icon={`las la-lg ${icon}`} />,
          classes: { input: classes.inputField },
        }}
        onChange={handleChange}
      />
    </FormControl>
  );
};

export default MuiTextField;
