import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
import { useStyles, useStylesButton } from './MuiToggleButtonGroup.styles';

type Orientation = "horizontal" | "vertical";
type DataType = {
  name: string;
  value: any;
}

interface Props {
    value: any;
    data: DataType[];
    exclusive: boolean;
    onChange: (event: React.MouseEvent<HTMLElement>, value: any) => void;
    orientation: Orientation;
}

const MuiToggleButtonGroup: React.FC<Props> = (props) => {
  const classes = useStyles();
  const buttonClasses = useStylesButton();
  const { value, data, exclusive, onChange, orientation } = props;

  return (
    <ToggleButtonGroup
      classes={classes}
      exclusive={exclusive}
      onChange={onChange}
      orientation={orientation}
      size="small"
      value={value}
    >
      {data.map((item, index) => (
        <ToggleButton
          classes={buttonClasses}
          value={item.value}
        >
          {item.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default MuiToggleButtonGroup;
