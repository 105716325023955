const ForumGroupActionTypes = {
  FETCH_FORUM_GROUP_START: 'FETCH_FORUM_GROUP_START',
  FETCH_FORUM_GROUP_SUCCESS: 'FETCH_FORUM_GROUP_SUCCESS',
  FETCH_FORUM_GROUP_ONE_SUCCESS: 'FETCH_FORUM_GROUP_ONE_SUCCESS',
  FETCH_FORUM_GROUP_COMMENT_SUCCESS: 'FETCH_FORUM_GROUP_COMMENT_SUCCESS',
  FETCH_FORUM_GROUP_OTHER_SUCCESS: 'FETCH_FORUM_GROUP_OTHER_SUCCESS',
  FETCH_FORUM_GROUP_FAILED: 'FETCH_FORUM_GROUP_FAILED',
  DESTROY_FORUM_GROUP: 'DESTROY_FORUM_GROUP',
  DESTROY_FORUM_GROUP_ONE: 'DESTROY_FORUM_GROUP_ONE',
  DESTROY_FORUM_GROUP_OTHER: 'DESTROY_FORUM_GROUP_OTHER',
  DESTROY_FORUM_GROUP_COMMENT: 'DESTROY_FORUM_GROUP_COMMENT'
};

export default ForumGroupActionTypes;
