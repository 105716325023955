import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  avatarCard: {
    border: '1px solid white',
    boxShadow: '0 0 0 1px #2D76F9',
    width: 34,
    height: 34,
    backgroundSize: '100%',
    aspectRatio: '1/1',
    alignItems: 'unset'
  },
  root: {
    marginBottom: 30
  },
  avatarMemberTitle: {
    width: 34,
    height: 34,
    backgroundSize: '100%',
    aspectRatio: '1/1',
    alignItems: 'unset'
  },
  avatarMember: {
    marginLeft: -15,
    width: 34,
    height: 34,
    backgroundSize: '100%',
    aspectRatio: '1/1',
    alignItems: 'unset'
  },
  totalMemberAvatar: {
    color: '#2D76F9',
    marginTop: 5
  },
  memberAvatar: {
    marginLeft: -15,
    width: 34,
    height: 34,
    backgroundSize: '100%',
    aspectRatio: '1/1',
    alignItems: 'unset',
    backgroundColor: '#fff'
  },
  qtyMember: {
    marginLeft: 25,
    marginTop: 10,
    fontSize: '14px',
    '& span': {
      fontWeight: 600,
      color: '#2D76F9'
    }
  },
  createdAt: {
    fontWeight: 400,
    fontSize: '11px',
    color: '#94A5C3'
  },
  titleGroup: {
    fontWeight: 400,
    fontSize: '14px'
  },
  sectionTitle: {
    marginLeft: 10
  },
  sectionMember: {
    marginTop: 10
  },
  sectionChip: {
    marginTop: 11,
    fontSize: '12px'
  },
  sectionHeader: {
    marginTop: 6
  },
  length: {
    fontWeight: 400,
    '& span': {
      color: '#2D76F9'
    }
  },
  // paper: {
  //   boxShadow: 'none',
  //   borderRadius: 8,
  //   border: '2px solid #E5EAF2'
  // },
  titleForum: {
    fontWeight: 500,
    marginTop: 10
  },
  descForum: {
    marginTop: 10,
    fontSize: '12px'
  },
  iconForum: {
    marginLeft: 25,
    marginRight: 5
  },
  paper: {
    backgroundColor: '#2D76F91A',
    borderRadius: 12,
    padding: '8px 16px 8px 16px'
    // height: '34px'
  },
  showHide: {
    color: '#2D76F9',
    marginLeft: 10,
    fontWeight: 500,
    // fontSize: '12px',
    cursor: 'pointer'
  },
  countReply: {
    // fontSize: '12px'
  },
  comment: {
    fontWeight: 400,
    fontSize: '14px'
  },
  titlePage: {
    fontWeight: 500,
    marginBottom: 20,
    fontSize: '18px',
    marginTop: 10
  }
}));

export default useStyles;
