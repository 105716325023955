import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 16,
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: '0px 16px 40px rgba(125, 125, 125, 0.1)',
  },
  tab: {
    textTransform: 'capitalize',
  },
}));

export default useStyles;
