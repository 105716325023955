import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Avatar,
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  Divider,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ChatIcon from '@material-ui/icons/Chat';
import Group from '../../utils/interfaces/group.interface';
import useStyles from './GroupCard.styles';
import MuiSelectHorizontal from '../MuiSelectHorizontal';
import { deleteGroup } from '../../actions/group.action';

interface Props {
  group: Group[];
  page: number;
  setPage: any;
  updateSort?: any;
}

const GroupCard: React.FC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { group, page, setPage, updateSort } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorId, setAnchorId] = React.useState<null | HTMLElement>(null);
  const [sortBy, setSortBy] = React.useState<string>("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setAnchorId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorId(null);
  };
  const handleDeleteDialog = (groupsData: Group[], id: any) => {
    const selectedGroup = groupsData.find((item) => item.id === id);
    handleClose();
    if (selectedGroup && window.confirm('Apakah anda yakin ingin menghapus grup ini?')) {
      dispatch(deleteGroup(selectedGroup.id));
    }
  };

  const handleSortBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = event.target.value;
    setSortBy(newValue);
    setPage(0);
    updateSort(newValue);
  };

  const sortChoices = [
    {
      name: 'Terbaru'
    },
    {
      name: 'Terlama'
    },
    {
      name: 'Judul A-Z'
    },
    {
      name: 'Judul Z-A'
    }
  ];

  return (
    <>
      <Grid item xs={12} md={6}>
        <Box marginY={3} display="flex" justifyContent="flex-start" alignItems="center">
          <Typography className={classes.length}>
            Menampilkan&nbsp;
            <span>{rowsPerPage}</span>
            &nbsp;dari&nbsp;
            <span>{group.length}</span>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box marginY={1} display="flex" justifyContent="flex-end">
          <MuiSelectHorizontal
            value={sortBy}
            label="Urutkan:"
            firstMenu="urutan..."
            data={sortChoices}
            handleChange={handleSortBy}
          />
        </Box>
      </Grid>
      {group.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((groups, idx) => (
        <Grid item md={6} xs={12} key={groups.id}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={11} xs={10}>
                  <Box display="flex" className={classes.sectionHeader}>
                    <Avatar className={classes.avatarCard}>
                      <img src={groups.imageUrl} alt="" />
                    </Avatar>
                    <div className={classes.sectionTitle}>
                      <Typography className={classes.titleGroup}>{groups.name}</Typography>
                      <Typography className={classes.createdAt}>
                        Dibuat oleh&nbsp;
                        {groups.creator.username}
                        ,&nbsp;
                        {moment(groups.createdAt).format('LL')}
                      </Typography>
                    </div>
                  </Box>
                </Grid>
                <Grid item md={1} xs={2}>
                  <Box display="flex" justifyContent="end" className={classes.sectionHeader}>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleClick(event, groups.id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id={`simple-menu-${groups.id}`}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      classes={{
                        paper: classes.paper
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <ListItem component={RouterLink} to="/forum-diskusi-detail" button>
                          <ListItemIcon>
                            <Box display="flex" alignItems="center">
                              <SearchIcon fontSize="small" />
                              <Box marginLeft={1}>
                                <Typography variant="inherit" noWrap>
                                  Detail Group
                                </Typography>
                              </Box>
                            </Box>
                          </ListItemIcon>
                        </ListItem>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <ListItem component={RouterLink} to="/forum-diskusi-detail" button>
                          <ListItemIcon>
                            <Box display="flex" alignItems="center">
                              <AssessmentIcon fontSize="small" color="primary" />
                              <Box marginLeft={1}>
                                <Typography variant="inherit" noWrap>
                                  Spesifik Target
                                </Typography>
                              </Box>
                            </Box>
                          </ListItemIcon>
                        </ListItem>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <ListItem component={RouterLink} to="/forum-diskusi-detail" button>
                          <ListItemIcon>
                            <Box display="flex" alignItems="center">
                              <ListAltIcon fontSize="small" color="primary" />
                              <Box marginLeft={1}>
                                <Typography variant="inherit" noWrap>
                                  Aktivitas Harian
                                </Typography>
                              </Box>
                            </Box>
                          </ListItemIcon>
                        </ListItem>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <ListItem component={RouterLink} to={`forum-diskusi/${anchorId}`} button>
                          <ListItemIcon>
                            <Box display="flex" alignItems="center">
                              <ChatIcon fontSize="small" color="primary" />
                              <Box marginLeft={1}>
                                <Typography variant="inherit" noWrap>
                                  Forum Diskusi
                                </Typography>
                              </Box>
                            </Box>
                          </ListItemIcon>
                        </ListItem>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDeleteDialog(group, anchorId)}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <Box display="flex" alignItems="center">
                              <DeleteIcon fontSize="small" />
                              <Box marginLeft={1}>
                                <Typography variant="inherit" noWrap>
                                  Hapus Group
                                </Typography>
                              </Box>
                            </Box>
                          </ListItemIcon>
                        </ListItem>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Divider className={classes.sectionMember} />
                </Grid>
                <Grid item md={12}>
                  <Box display="flex">
                    {groups.groupMembership.slice(0, 5).map((imgGroup, idImg) => (
                      <Avatar className={classes.avatarMember} key={idImg}>
                        <img src={imgGroup.member.profileImage} alt="" />
                      </Avatar>
                    ))}
                    <Typography className={classes.qtyMember}>
                      <span>{groups.membersCount}</span>
                      &nbsp;Anggota
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <br />
                </Grid>
                <Grid item md={4}>
                  <Box display="flex">
                    <AssessmentIcon color="primary" />
                    <div>
                      <Typography>{groups.specificTargetCount}</Typography>
                      <Typography className={classes.createdAt}>Spesifik Target</Typography>
                    </div>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box display="flex">
                    <ListAltIcon color="primary" />
                    <div>
                      <Typography>{groups.activityCount}</Typography>
                      <Typography className={classes.createdAt}>Aktivitas Harian</Typography>
                    </div>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Box display="flex">
                    <ChatIcon color="primary" />
                    <div>
                      <Typography>{groups.forumsCount}</Typography>
                      <Typography className={classes.createdAt}>Forum Diskusi</Typography>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} md={12}>
        <Box justifyContent="center" display="flex">
          <TablePagination
            rowsPerPageOptions={matches ? [] : [10, 20, 30]}
            component="div"
            count={group.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Grid>
    </>
  );
};

export default GroupCard;
